import {
  DEFAULT_PAGE_SIZE,
  OUTDATED_BROWSER_ACCOUNTS_TABLE_COLUMNS,
  OUTDATED_BROWSER_ACCOUNTS_SORT_BY,
  USERTYPE_FILTERS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsMobile } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';

import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import {
  useOutdatedBrowserAccountsPreviewQuery,
} from '@hooks/security';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { useLocation } from 'react-router-dom';
import {getGroupOrLocationFilterAsQueryParam
} from '../helper';
import { createCellValue } from './utils';
import useInsightsStore from '@store/insightsStore';

const pageSize = DEFAULT_PAGE_SIZE;

export const OutdatedBrowserAccountsPage: React.FC = () => {
  const isMobile = useIsMobile();
  const days = new URLSearchParams(useLocation().search).get("days") || "30";
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'logincount'),
    order: withDefault(StringParam, 'desc'),
    filter: withDefault(StringParam, ''),
  });
  const {
    groupIds, locationIds, filterData, transformedFilterData
  } = useInsightsStore();

  // table
  const query = useOutdatedBrowserAccountsPreviewQuery({
    pageSize,
    pageNumber,
    days,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    accountType: queryParams.filter
      ? queryParams.filter
        .split(',')
        .filter((filter) => USERTYPE_FILTERS.includes(filter))
      : [],
    browserType: queryParams.filter
      ? queryParams.filter
        .split(',')
        .filter((filter) => filterData?.browsers.includes(filter))
      : [],
    locationList: getGroupOrLocationFilterAsQueryParam(
      queryParams.filter,
      locationIds
    ),
    groupList: getGroupOrLocationFilterAsQueryParam(
      queryParams.filter,
      groupIds
    ),
  });
  const getTableData = useTableData(
    query,
    OUTDATED_BROWSER_ACCOUNTS_TABLE_COLUMNS,
    createCellValue
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: API_ENDPOINTS.OUTDATED_BROWSER_ACCOUNTSS_EXPORT,
    params: {
      pageSize,
      pageNumber,
      days,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      browserType: queryParams.filter
        ? queryParams.filter
          .split(',')
          .filter((filter) => filterData?.browsers.includes(filter))
        : [],
      accountType: queryParams.filter
        ? queryParams.filter
          .split(',')
          .filter((filter) => USERTYPE_FILTERS.includes(filter))
        : [],
      locationList: getGroupOrLocationFilterAsQueryParam(
        queryParams.filter,
        locationIds
      ),
      groupList: getGroupOrLocationFilterAsQueryParam(
        queryParams.filter,
        groupIds
      ),
    },
    filename: `Clevr360_Outdated_browser_accounts_data_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <div className="mt-2">
      <TableWrapper
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by account, location, group, browser'}
        columns={OUTDATED_BROWSER_ACCOUNTS_TABLE_COLUMNS}
        data={getTableData()}
        sortData={OUTDATED_BROWSER_ACCOUNTS_SORT_BY}
        filterData={transformedFilterData}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={false}
        sliceColumns={1}
        isLicensePage={true}
        floatingFilterButton={true}
        hasFilterBanner={false}
        floatingFilterButtonLeft={!isMobile ? '190px' : ''}
        searchCountStatsLabel="accounts / browsers"
        filterHeaderText="Filter outdated browser data"
        isDownloadAvailable={true}
        displayAsDropdownFilterItems={["Browsers"]}
        handleDownload={handleDownloadClick}
        noResultTitle={search ? 'No results matching your search' 
          : 'There are no outdated browsers identified on your Microsoft 365 service.'}
        noResultSubtitle={search ? 'Try a less specific search.' : ''}
      />
    </div>
  );
};

export default OutdatedBrowserAccountsPage;
