import {
  DEFAULT_PAGE_SIZE,
  ONLINE_STATUS,
  VENDORS,
  DEVICE_INVENTORY_SUMMARY_TABLE_COLUMNS,
  DEVICE_INVENTORY_SUMMARY_SORT_BY,
} from '@common/constants';
import { TableWrapper } from '@components/partials';
import { IOption, OrderBy } from '@common/types';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import {
  formatDate,
  getFilterValues,
  getFirstMatchedFilterValue,
  useTableData,
} from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { createCellValue } from '../utils';
import {
  useDeviceInventorySummaryFilterQuery,
  useDeviceInventorySummaryPreviewQuery,
} from '@hooks/utilisation';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';

const pageSize = DEFAULT_PAGE_SIZE;

const DeviceInventorySummaryPage: React.FC = () => {
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'device'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const { data: filters } = useDeviceInventorySummaryFilterQuery();
  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
  };

  let typesData: string[] = [];

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  const typesFilerOptions: IOption[] = [];
  const transformedFilterData: FilterData[] = [];

  if (filters) {
    Object.keys(filters).forEach((property) => {
      if (property === 'vendor') {
        const filterDataArray: FilterData = {
          label: getPropertyLabel(property),
          name: property.toLowerCase(),
          options: [],
          singleSelect: !!(property === 'vendor'),
        };

        const propertyData = filters[property];

        if ('value' in propertyData) {
          const item = propertyData;

          const option = {
            label: item.value as string,
            value: item.value as string,
          };

          filterDataArray.options.push(option);
        } else {
          const items = propertyData;
          items.forEach((item) => {
            const option = {
              label: item.value || item.vendor,
              value: item.value || item.vendor,
            };
            filterDataArray.options.push(option);
          });
        }

        transformedFilterData.push(filterDataArray);
      }
      if (property === 'type') {
        typesData = filters[property]?.map(item => item.value || "");
        filters[property]?.forEach((item) => {
          typesFilerOptions.push({
            label: item.value || '',
            value: item.value || '',
          });
        });
        transformedFilterData.push({
          label: 'Type',
          name: 'type',
          singleSelect: false,
          options: typesFilerOptions,
        });
      }
    });
  }

  // table
  const query = useDeviceInventorySummaryPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    typeList:
      queryParams.filter && typesData
        ? queryParams.filter
            .split(',')
            .filter((filter) => typesData.includes(filter))
        : [],
    vendorList: getFilterValues(queryParams, VENDORS),
    onlineStatus: getFirstMatchedFilterValue(queryParams, ONLINE_STATUS),
  });
  const getTableData = useTableData(
    query,
    DEVICE_INVENTORY_SUMMARY_TABLE_COLUMNS,
    createCellValue
  );
  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: API_ENDPOINTS.HARDWARE_INVENTORY_SUMMARY_EXPORT,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      typeList:
        queryParams.filter && typesData
          ? queryParams.filter
              .split(',')
              .filter((filter) => typesData.includes(filter))
          : [],
      vendorList: getFilterValues(queryParams, VENDORS),
    },
    filename: `Clevr360_Device_inventory_Summary_${formatDate(
      new Date().toString()
    )}.csv`,
  });
  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <div className="mt-[15px]">
      <TableWrapper
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by vendor, device'}
        columns={DEVICE_INVENTORY_SUMMARY_TABLE_COLUMNS}
        data={getTableData()}
        sortData={DEVICE_INVENTORY_SUMMARY_SORT_BY}
        filterData={transformedFilterData}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={true}
        sliceColumns={1}
        floatingFilterButton={false}
        isDownloadAvailable={true}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="devices"
        filterHeaderText="Filter device inventory"
        handleDownload={handleDownloadClick}
        noResultTitle={
          search
            ? 'No results matching your search'
            : 'You have no devices configured'
        }
        noResultSubtitle={
          search
            ? 'Try a less specific search.'
            : 'Data will appear here once devices have been configured.'
        }
      />
    </div>
  );
};

export default DeviceInventorySummaryPage;
