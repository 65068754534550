export const createCellValueForMailboxesTab = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];
  switch (property) {
    case 'mailboxId':
      return (
        <>
          <span>{value}</span>
        </>
      );
    case 'delegatesCount':
      return (
        <>
          <span>{value}</span>
        </>
      );
  }
};

export const getSuspiciousActivityTypes = (percentText: string) => {
  const text = percentText?.split(' ; ');
  const increasedTypes: { [key: string]: boolean } = {};
  const decreasedTypes: { [key: string]: boolean } = {};
  text?.map((item) => {
    const key = item?.split(':')[0];
    const value = !!item?.split(':')[1];
    if (value) {
      increasedTypes[key] = value;
    } else {
      decreasedTypes[key] = value;
    }
  });
  return { increasedTypes, decreasedTypes };
};

export const getSuspiciousActivityTypesText = (
  increasedTypes: { [key: string]: boolean },
  decreasedTypes: { [key: string]: boolean }
): string => {
  let increasedText = '';
  let decreasedText = '';

  Object.keys(increasedTypes).map((typeKey: string, index: number) => {
    const isLast = index === Object.keys(increasedTypes).length - 1;
    const isBeforeLast = index === Object.keys(increasedTypes).length - 2;
    increasedText += `${
      isLast && Object.keys(increasedTypes).length > 1 ? ' and' : ''
    } ${typeKey}${!isLast && !isBeforeLast ? ', ' : ''}`;
  });

  Object.keys(decreasedTypes).map((typeKey: string, index: number) => {
    const isLast = index === Object.keys(decreasedTypes).length - 1;
    const isBeforeLast = index === Object.keys(decreasedTypes).length - 2;
    decreasedText += `${
      isLast && Object.keys(decreasedTypes).length > 1 ? ' and' : ''
    } ${typeKey}${!isLast && !isBeforeLast ? ', ' : ''}`;
  });

  return `${increasedText}${
    Object.keys(decreasedTypes).length
      ? `, while ${decreasedText} have decreased`
      : ''
  }`;
};

export const getSuspiciousActivityCountryText = (data: any): string => {
  let text = '';
  data
    ?.sort(
      (
        a: { countryName: string; numberOfSuspiciousEventsCount: number },
        b: { countryName: string; numberOfSuspiciousEventsCount: number }
      ) => b?.numberOfSuspiciousEventsCount - a?.numberOfSuspiciousEventsCount
    )
    ?.slice(0, 3)
    ?.map((item: { countryName: string }, index: number) => {
      text += `${index === 2 ? ' and ' : ''}${item?.countryName}${
        index < 1 ? ', ' : ''
      }`;
    });
  return text;
};

function parseDate(dateStr: string) {
  const [day, month, year] = dateStr.split('/').map(Number);
  return new Date(year, month - 1, day);
}

export const getReportPreviousPeriodByDateRange = (period: string) => {
  const dates = period.split(' - ');
  const startDate = parseDate(dates[0]);
  const endDate = parseDate(dates[1]);
  const differenceInTime: number = endDate.getTime() - startDate.getTime();
  const differenceInDays: number = differenceInTime / (1000 * 3600 * 24);
  if (differenceInDays === 1) return 'day';
  if (differenceInDays === 7) return '7 days';
  if (differenceInDays >= 365) return 'year';
  if (differenceInDays >= 90) return 'quarter';
  if (differenceInDays >= 30) return 'month';
  return 'month';
};
