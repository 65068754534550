import {
  DEFAULT_PAGE_SIZE,
  DashboardTiles,
  INACTIVE_LICENSES_TABLE_COLUMNS,
  LICENSES_SORT_BY,
  VENDORS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { Location } from '@hooks/locations/types';
import { TableWrapper } from '@components/partials';
import { OverlayPage } from '@components/partials/OverlayPage';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { Banner, DashboardIcon, LightInfoIcon } from '@components/ui';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import {
  useLicencesActivityQuery,
  useLicencesActivityTrendQuery,
  useLicencesFiltersQuery,
  useLicensesActivityPreviewQuery,
} from '@hooks/licenses';
import {
  LicensesFilterData,
  LicensesFiltersResponse,
} from '@hooks/licenses/types';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { TextTile } from '@pages/InsightsAndAnalytics/tiles/TextTile';
import {
  formatDate,
  formatLicensesFilter,
  getTrendData,
  getTrendLabels,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;

const InactiveLicensePage: React.FC = () => {
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const isDesktop = useIsDesktop();
  const [days, setDays] = useState('30');
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdown('30'),
    },
    {
      id: '2',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdown('60'),
    },
    {
      id: '3',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdown('90'),
    },
  ];

  const [compareAgainst, setCompareAgainst] = useState('prev');

  const handleDropdown = (value: string) => {
    setDays(value);
    handleCompareDropdown('prev');
  };

  const handleCompareDropdown = (value: string) => {
    setCompareAgainst(value);
  };

  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'assignedto'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const { data: licencesActivityData, isLoading: isLicencesActivityLoading } =
    useLicencesActivityQuery({
      days: '1',
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    });

  // table
  const {
    data: licencesActivityTrendData,
    isLoading: isLicencesActivityTrendDataLoading,
  } = useLicencesActivityTrendQuery({
    days,
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    licenseTypeList: formatLicensesFilter(queryParams.filter),
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
  });

  const labels = getTrendLabels(licencesActivityTrendData?.trend || [], +days);

  const trendData = getTrendData(licencesActivityTrendData?.trend || [], +days);

  const query = useLicensesActivityPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
    licenseTypeList: formatLicensesFilter(queryParams.filter),
  });

  const getTableData = useTableData(
    query,
    INACTIVE_LICENSES_TABLE_COLUMNS,
    createCellValue
  );

  const { data: filterData, isFetched: filterDataFetched } =
    useLicencesFiltersQuery(
      'true',
      queryParams.filter.includes('freeLicenses')
    );
  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
    licensetype: 'License Type',
  };

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  if (filterDataFetched && locationDataFetched && groupsDataFetched) {
    if (filterData) {
      Object.keys(filterData).forEach((property) => {
        const filterDataArray: FilterData = {
          label: getPropertyLabel(property),
          name: property.toLowerCase(),
          options: [],
          singleSelect: !!(property === 'vendor'),
        };
        const propertyData =
          filterData[property as keyof LicensesFiltersResponse];

        const items = propertyData as unknown as LicensesFilterData[];
        items.forEach((item) => {
          if (item.vendor === "RingCentral") return;
          const option = {
            label: item.value || item.vendor,
            value: item.value || item.vendor,
          };

          filterDataArray.options.push(option);
        });
        
        filterDataArray.options.forEach((option) => {
          const matchingVendor = filterData.licenseType.find(
            (v) => v.value === option.value
          );

          if (matchingVendor) {
            option.vendor = matchingVendor.vendor;
          }
        });

        transformedFilterData.push(filterDataArray);
      });

      transformedFilterData.push({
        label: 'Free licences',
        name: 'freeLicenses',
        singleSelect: true,
        options: [{ label: 'Show free licenses', value: 'freeLicenses' }],
      });
    }

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.InactiveLicensesTrendOverTime:
          return {
            days,
            vendorList: queryParams.filter
              ? queryParams.filter
                  .split(',')
                  .filter((filter) => VENDORS.includes(filter as any))
              : [],
            licenseTypeList: formatLicensesFilter(queryParams.filter),
          };
        case DashboardTiles.InactiveLicensesSnapshot:
          return {
            days,
          };
        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const selectedSortItem = sortMenuItems.find((item) => item.value === days);

  const legendLabels = [(selectedSortItem && selectedSortItem.label) || ''];

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: API_ENDPOINTS.LICENSES_ACTIVITY_EXPORT,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : [],
      licenseTypeList: formatLicensesFilter(queryParams.filter),
    },
    filename: `Clevr360_Inactive_licenses_${formatDate(
      new Date().toString()
    )}.csv`,
  });
  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Inactive licenses`}
    >
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <LineChartTile
              classNames={styles.lineChartTile}
              initialSelectedItem={selectedSortItem}
              legendLabels={legendLabels}
              dataSetColors={['#00CF6C']}
              sortData={sortMenuItems}
              compareData={undefined}
              compareLabel=""
              tooltipLabel={'inactive licenses'}
              headerTitle={'Inactive licenses: Trend over time'}
              contextData={createMenuItems(
                DashboardTiles.InactiveLicensesTrendOverTime
              )}
              isLoading={isLicencesActivityTrendDataLoading}
              labels={labels}
              data={[trendData]}
              dataSetTypes={[LineChartTypes.Dots]}
            />

            <DoughnutChartTile
              classNames={styles.doughnutChartTile}
              contextData={[]}
              headerTitle={'Inactive licenses: Snapshot'}
              isLoading={isLicencesActivityLoading}
              data={
                licencesActivityData ? Object.values(licencesActivityData) : []
              }
              dataLabels={['Inactive licenses', 'Active licenses']}
              showBracketLabelValue={true}
            />
          </>
        )}

        <div className="mt-5 lg:mt-0">
          <TextTile
            classNames={styles.textTile}
            headerTitle={'Recommendations'}
          >
            <div className={styles.textTileContainer}>
              <div className={styles.textTileItem}>
                <LightInfoIcon
                  color={styles.textTileItemIcon}
                  classNames="w-4 h-4"
                />
                <span>
                  Licenses may be inactive because people don’t know they have
                  access to them.
                </span>
              </div>
              <div className={styles.textTileItem}>
                <LightInfoIcon
                  color={styles.textTileItemIcon}
                  classNames="w-4 h-4"
                />
                <span>
                  If people don’t need the licenses they have, consider
                  cancelling or reassigning them.
                </span>
              </div>
            </div>
          </TextTile>
        </div>
      </div>
      <Banner
        variant="info"
        message="RingCentral’s technology doesn’t currently allow us to access to this data. We’re working on it."
        className="mb-6 flex-grow"
        title="This table doesn’t include RingCentral licenses"
      />
      <TableWrapper
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by license type, name, email address'}
        columns={INACTIVE_LICENSES_TABLE_COLUMNS}
        data={getTableData()}
        sortData={LICENSES_SORT_BY}
        filterData={transformedFilterData}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={true}
        sliceColumns={2}
        floatingFilterButton={true}
        isDownloadAvailable={true}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="licenses"
        filterHeaderText="Filter inactive licenses"
        handleDownload={handleDownloadClick}
      />
    </OverlayPage>
  );
};

export default InactiveLicensePage;
