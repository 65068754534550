import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useFailedMSLoginTypesSnapshotQuery } from '@hooks/failedLoginData';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
};

export const FailedLoginTypes: React.FC<TilesProps> = ({
  id,
  params,
  classNames,
  isSnapshot,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.FailedLoginTypes,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: failedLoginTypes,
    isLoading: isFailedLoginTypesLoading,
    refetch,
  } = useFailedMSLoginTypesSnapshotQuery(parameters);

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({
      days: updatedParams.days,
    });
  }, [params]);

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDropdownChange('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange('90'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const getContextMenu = (): MenuItem[] => {
    if (!isSnapshot) {
      return isOnDashboardPage ? removeMenuItem : addMenuItem;
    } else {
      return [];
    }
  };

  return (
    parameters && (
      <DoughnutChartTile
        dropdownMenuItemClasses={'!w-[300px]'}
        initialCompareSelectedItem={compareMenuItems.find(
          (item) => item.value === parameters.days
        )}
        contextData={getContextMenu()}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        sortData={!isSnapshot ? compareMenuItems : []}
        headerTitle={'Failed MS logins: Types'}
        isLoading={isFailedLoginTypesLoading}
        data={
          failedLoginTypes && failedLoginTypes.snapshot
            ? Object.values(failedLoginTypes.snapshot).map((item) => +item)
            : []
        }
        buttonLabel={!isSnapshot ? 'Failed login data' : ''}
        linkPath={ANALYTICS_NAVIGATION_MAP['failedLoginTypesData']}
        dataLabels={
          failedLoginTypes
            ? Object.keys(failedLoginTypes.snapshot).map((item) => item + ':')
            : []
        }
        handleButtonClick={handleButtonClick()}
        showMessageIfEmpty={false}
      />
    )
  );
};
