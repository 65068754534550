import {
    DEFAULT_PAGE_SIZE,
    SUSPICIOUS_ACTIVITY_EVENTS_SORT_BY,
    SUSPICIOUS_ACTIVITY_EVENTS_TABLE_COLUMNS,
} from '@common/constants';
import { IOption, OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { PeopleSortBy } from '@hooks/people/types';
import {
    useSuspiciousActivityEventsFiltersQuery,
    useSuspiciousActivityEventsPreviewQuery,
} from '@hooks/security';
import { usePagination } from '@hooks/utils/pagination';
import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { useLocation } from 'react-router-dom';
import { createCellValue } from './utils';

const pageSize = DEFAULT_PAGE_SIZE;

const SuspiciousActivityEventsPage: React.FC = () => {

    const [queryParams, setQueryParams] = useQueryParams({
        days: withDefault(StringParam, '30'),
        search: withDefault(StringParam, ''),
        sortBy: withDefault(StringParam, 'datetime'),
        order: withDefault(StringParam, 'desc'),
        filter: withDefault(StringParam, ''),
      });
    const [search, setSearch] = useState('');
    const { pageNumber, setPageNumber } = usePagination();

    const days = queryParams.days; // Get the 'days' parameter from queryParams
   

    const { data } = useSuspiciousActivityEventsFiltersQuery(days);

    const transformedFilterData: FilterData[] = [];

    if (data) {
        Object.keys(data).forEach((key) => {
          let filterOptions: IOption[] = [];
    
          if (Array.isArray(data[key].value)) {
            filterOptions = (data[key].value as string[]).map((value) => ({
              label: value,
              value,
            }));
          } else if (typeof data[key].value === 'object') {
            filterOptions = Object.entries(data[key].value).map(
              ([value, label]) => ({ label: label as string, value })
            );
          }
    
          transformedFilterData.push({
            label: data[key].key.replace(/(?<!^)([A-Z])/g, ' $1'),
            name: key,
            singleSelect: false,
            options: filterOptions,
          });
        });
      }

    const filterValues = queryParams.filter.split(',');

    // table
  const query = useSuspiciousActivityEventsPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    days,
    ipTypes:
      typeof data?.ipTypes?.value === 'object'
        ? filterValues.filter((value) =>
            Object.keys(data.ipTypes.value).includes(value)
          )
        : [],
    eventTypes:
      typeof data?.eventTypes?.value === 'object'
        ? filterValues.filter((value) =>
            Object.keys(data.eventTypes.value).includes(value)
          )
        : [],
    countries:
      typeof data?.countries?.value === 'object'
        ? filterValues.filter((value) =>
            Object.keys(data.countries.value).includes(value)
          )
        : [],
  });

    const getTableData = useTableData(
        query,
        SUSPICIOUS_ACTIVITY_EVENTS_TABLE_COLUMNS,
        createCellValue
    );

    const [shouldDownload, setShouldDownload] = useState(false);
    const { refetch: downloadCsv } = useDownloadCsv({
        shouldDownload,
        endpoint: `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_EVENTS_EXPORT}`,
        params: {
          pageSize,
          pageNumber,
          searchTerm: search,
          sortBy: queryParams.sortBy as PeopleSortBy,
          order: queryParams.order as OrderBy,
          days,
          ipTypes:
            typeof data?.ipTypes?.value === 'object'
              ? filterValues.filter((value) =>
                  Object.keys(data.ipTypes.value).includes(value)
                )
              : [],
          eventTypes:
            typeof data?.eventTypes?.value === 'object'
              ? filterValues.filter((value) =>
                  Object.keys(data.eventTypes.value).includes(value)
                )
              : [],
          countries:
            typeof data?.countries?.value === 'object'
              ? filterValues.filter((value) =>
                  Object.keys(data.countries.value).includes(value)
                )
              : [],
        },
        filename: `Clevr360_Suspicious_activity_data_${formatDate(
          new Date().toString()
        )}.csv`,
      });
    
      const handleDownloadClick = () => {
        setShouldDownload(true);
        downloadCsv();
        setShouldDownload(false);
      };
    
      return (
        <div className="mt-2">
            <TableWrapper
                search={search}
                setSearch={setSearch}
                searchPlaceholder={
                    'Search by event type, account, browser name, country, ASN name'
                }
                columns={SUSPICIOUS_ACTIVITY_EVENTS_TABLE_COLUMNS}
                data={getTableData()}
                sortData={SUSPICIOUS_ACTIVITY_EVENTS_SORT_BY}
                filterData={transformedFilterData}
                searchKey="search"
                query={query}
                refetchQuery={query.refetch}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                setPageNumber={setPageNumber}
                isCollapsable={false}
                isLicensePage={true}
                floatingFilterButton={false}
                hasFilterBanner={false}
                searchCountStatsLabel="events"
                filterHeaderText="Filter suspicious activity"
                isDownloadAvailable={true}
                handleDownload={handleDownloadClick}
                sliceColumns={2}
                noResultTitle={search ? 'No results matching your search' 
                    : 'There is no suspicious activity identified on your Microsoft 365 service.'}
                noResultSubtitle={search ? 'Try a less specific search.' : ''}
            />
        </div>
      );
    };
    
    export default SuspiciousActivityEventsPage;