import { API_ENDPOINTS } from '@api/ApiEndpoints';
import { UseQueryResult, useQuery } from 'react-query';
import {
  DeviceUsageQueryOptions,
  DeviceUsageSnapshotResponse,
  DeviceInventoryPreviewQueryOptions,
  DeviceInventoryPreviewResponse,
  HardwareInventoryTypesResponse,
  InventoryChangesTrendResponse,
  InventorySnapshotResponse,
  LicenseInventoryPreviewResponse,
  LicensesInventoryAssignmentPreviewQueryOptions,
  LicensesInventoryAssignmentPreviewResponse,
  LicensesInventoryPreviewQueryOptions,
  SharePointQueryOptions,
  SharePointTrendQueryOptions,
  SharePointUsagePreviewQueryOptions,
  SharePointUsagePreviewResponse,
  SharePointUsageTrendResponse,
  UseHardwareInventorySnapshotQueryOptions,
  UseLicencesInventorySnapshotQueryOptions,
  UseLicencesInventoryTrendQueryOptions,
  MeetingRoomsDevicesResponse,
  MeetingRoomDeviceUsageQueryOptions,
  MeetingRoomDeviceUsageSnapshotResponse,
  TeamsGroupsQueryOptions,
  DevicesFiltersResponse,
  DeviceInventorySummaryPreviewResponse,
  DevicesSummaryFiltersResponse,
} from './types';
import { ConfiguredTrunksSnapshotResponse, NumberSnapshotResponse } from '@common/types';
import { useHttpClient } from '@hooks/utils/httpConfig';
import { getSearchQueryParams } from '@utils/index';

export function useLicencesInventorySnapshotQuery({
  vendorList,
}: UseLicencesInventorySnapshotQueryOptions = {}): UseQueryResult<InventorySnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchLicenseInventorySnapshot =
    async (): Promise<InventorySnapshotResponse> => {
      const params = new URLSearchParams();
      if (vendorList) {
        vendorList.forEach((vendor) => {
          params.append('VendorList', vendor);
        });
      }
      return httpClient.get<InventorySnapshotResponse>(
        `${API_ENDPOINTS.LICENSE_INVENTORY}?${params.toString()}`
      );
    };

  return useQuery(
    ['licenseInventory', vendorList],
    fetchLicenseInventorySnapshot
  );
}

export function useLicensesInventoryPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  vendorList,
  expiryStatus,
  autoRenew,
  renewalPeriod,
  includeFreeLicenses,
  subscriptionNameList,
}: LicensesInventoryPreviewQueryOptions = {}): UseQueryResult<LicenseInventoryPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<LicenseInventoryPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (autoRenew) baseParams.autoRenew = autoRenew;
    if (renewalPeriod) baseParams.renewalPeriod = renewalPeriod;

    if (includeFreeLicenses) {
      baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
    }

    if (expiryStatus)
      baseParams.expiryStatus = expiryStatus.replace('Status', '');

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';

    const subscriptionNameListParams = subscriptionNameList
      ? subscriptionNameList
        .map((licenseType) => `SubscriptionNameList=${licenseType}`)
        .join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.LICENSE_INVENTORY_PREVIEW
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${vendorListParams}&${subscriptionNameListParams}`;

    return httpClient.get<LicenseInventoryPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'licensesServiceUsagePreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      vendorList,
      renewalPeriod,
      autoRenew,
      expiryStatus,
      includeFreeLicenses,
      subscriptionNameList,
    ],
    fetchData
  );
}

export function useLicensesInventoryAssignmentPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  vendorList,
  locationList,
  groupList,
  includeFreeLicenses,
  licenseTypeList,
}: LicensesInventoryAssignmentPreviewQueryOptions = {}): UseQueryResult<LicensesInventoryAssignmentPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData =
    async (): Promise<LicensesInventoryAssignmentPreviewResponse> => {
      const params = getSearchQueryParams({
        pageSize,
        pageNumber,
        searchTerm,
        sortBy,
        order,
        includeFreeLicenses: includeFreeLicenses?.toString(),
        VendorList: vendorList,
        LocationList: locationList,
        GroupList: groupList,
        LicenseTypeList: licenseTypeList,
      });

      const endpoint = `${API_ENDPOINTS.LICENSE_INVENTORY_ASSIGNMENT_PREVIEW
        }?${params.toString()}`;
      return httpClient.get<LicensesInventoryAssignmentPreviewResponse>(
        endpoint
      );
    };

  return useQuery(
    [
      'licensesInventoryAssignmentPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      vendorList,
      groupList,
      locationList,
      includeFreeLicenses,
      licenseTypeList,
    ],
    fetchData
  );
}

export function useDeviceInventoryPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  typeList,
  vendorList,
  onlineStatus,
  locationList,
  groupList,
}: DeviceInventoryPreviewQueryOptions = {}): UseQueryResult<DeviceInventoryPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<DeviceInventoryPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (onlineStatus) baseParams.onlineStatus = onlineStatus;

    const typeListParams = typeList
      ? typeList.map((type) => `TypeList=${type}`).join('&')
      : '';

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.HARDWARE_INVENTORY_PREVIEW
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${typeListParams}${vendorListParams}&${locationListParams}&${groupListParams}`;

    return httpClient.get<DeviceInventoryPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'deviceInventoryPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      typeList,
      onlineStatus,
      vendorList,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useHardwareInventorySnapshotQuery({
  typeList,
}: UseHardwareInventorySnapshotQueryOptions = {}): UseQueryResult<InventorySnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchHardwareInventorySnapshot =
    async (): Promise<InventorySnapshotResponse> => {
      let typeListParams = '';
      if (typeList) {
        typeListParams = typeList
          ? typeList.map((type) => `TypeList=${type}`).join('&')
          : '';
      }

      return httpClient.get<InventorySnapshotResponse>(
        `${API_ENDPOINTS.HARDWARE_INVENTORY}${typeListParams ? `?${typeListParams.toString()}` : ''
        }`
      );
    };

  return useQuery(
    ['hardwareInventory', typeList],
    fetchHardwareInventorySnapshot
  );
}

export function useDeviceInventoryTypesQuery(): UseQueryResult<HardwareInventoryTypesResponse> {
  const httpClient = useHttpClient();
  const fetchHardwareInventoryTypes =
    async (): Promise<HardwareInventoryTypesResponse> => {
      return httpClient.get<HardwareInventoryTypesResponse>(
        `${API_ENDPOINTS.HARDWARE_INVENTORY_TYPES}`
      );
    };

  return useQuery(['hardwareInventoryTypes'], fetchHardwareInventoryTypes);
}

export function useDeviceInventorySummaryPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  typeList,
  vendorList,
}: DeviceInventoryPreviewQueryOptions = {}): UseQueryResult<DeviceInventorySummaryPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<DeviceInventorySummaryPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;

    const typeListParams = typeList
      ? typeList.map((type) => `TypeList=${type}`).join('&')
      : '';

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.HARDWARE_INVENTORY_SUMMARY_PREVIEW
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${typeListParams}&${vendorListParams}`;

    return httpClient.get<DeviceInventorySummaryPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'deviceInventorySummaryPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      typeList,
      vendorList,
    ],
    fetchData
  );
}


export function useSharePointUsageSnapshotQuery({
  dataType,
  days,
}: SharePointQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (dataType) baseParams.DataType = dataType;

    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.SHARE_POINT_USAGE}?${new URLSearchParams(
      baseParams
    ).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['sharePointUsageSnapshot', { dataType, days }], fetchData);
}

export function useActiveSharePointSites({
  days,
  includePersonalSites,
}: SharePointQueryOptions = {}): UseQueryResult<number> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<number> => {
    const params = getSearchQueryParams({
      days,
      includePersonalSites: includePersonalSites?.toString(),
    });

    const endpoint = `${API_ENDPOINTS.SHARE_POINT_ACTIVE_SITES
      }?${params.toString()}`;

    return httpClient.get<number>(endpoint);
  };

  return useQuery(
    ['activeSharePointSites', { days, includePersonalSites }],
    fetchData
  );
}

export function useTotalSharePointSites({
  includePersonalSites,
}: SharePointQueryOptions = {}): UseQueryResult<number> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<number> => {
    const params = getSearchQueryParams({
      includePersonalSites: includePersonalSites?.toString(),
    });

    const endpoint = `${API_ENDPOINTS.SHARE_POINT_TOTAL_SITES
      }?${params.toString()}`;

    return httpClient.get<number>(endpoint);
  };

  return useQuery(
    ['totalSharePointSites', { includePersonalSites }],
    fetchData
  );
}

export function useSharePointUsageTrendQuery({
  days,
  dataType,
}: SharePointTrendQueryOptions = {}): UseQueryResult<SharePointUsageTrendResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<SharePointUsageTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.days = days;
    if (dataType) baseParams.DataType = dataType;

    const endpoint = `${API_ENDPOINTS.SHARE_POINT_USAGE_TREND
      }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<SharePointUsageTrendResponse>(endpoint);
  };

  return useQuery(['sharePointUsageTrend', { days, dataType }], fetchData);
}

export function useSharePointUsagePreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  includePersonalSites,
  days,
}: SharePointUsagePreviewQueryOptions = {}): UseQueryResult<SharePointUsagePreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<SharePointUsagePreviewResponse> => {
    const params = getSearchQueryParams({
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      includePersonalSites: includePersonalSites?.toString(),
    });

    const endpoint = `${API_ENDPOINTS.SHARE_POINT_USAGE_PREVIEW
      }?${params.toString()}`;

    return httpClient.get<SharePointUsagePreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'sharePointUsagePreview',
      {
        pageSize,
        pageNumber,
        searchTerm,
        sortBy,
        order,
        includePersonalSites,
        days,
      },
    ],
    fetchData
  );
}

export function useGroupsTeamsInventorySnapshotQuery({
  vendor,
}: TeamsGroupsQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (vendor) baseParams.Vendor = vendor;

    const endpoint = `${API_ENDPOINTS.TEAMS_GROUPS_SNAPSHOT
      }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['teamsGroups', vendor], fetchSnapshot);
}

export function useDeviceUsageSnapshotQuery({
  days,
  type,
}: DeviceUsageQueryOptions = {}): UseQueryResult<DeviceUsageSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<DeviceUsageSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (type) baseParams.DeviceType = type;
    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.DEVICE_USAGE_SNAPSHOT
      }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<DeviceUsageSnapshotResponse>(endpoint);
  };

  return useQuery(['deviceUsage', days, type], fetchSnapshot);
}

export function useLicencesInventoryChangesTrendQuery({
  days,
}: UseLicencesInventoryTrendQueryOptions = {}): UseQueryResult<InventoryChangesTrendResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<InventoryChangesTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.LICENSES_INVENTORY_CHANGES
      }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<InventoryChangesTrendResponse>(endpoint);
  };

  return useQuery(
    [
      'licencesInventoryChangesOverTime',
      {
        days,
      },
    ],
    fetchData
  );
}

export function useMeetingRoomsDevicesQuery(): UseQueryResult<MeetingRoomsDevicesResponse> {
  const httpClient = useHttpClient();
  const fetchRooms = async (): Promise<MeetingRoomsDevicesResponse> => {
    return httpClient.get<MeetingRoomsDevicesResponse>(
      `${API_ENDPOINTS.MEETING_ROOMS_DEVICES}`
    );
  };

  return useQuery(['meetingRoomsDevices'], fetchRooms);
}

export function useMeetingRoomDeviceUsageSnapshotQuery({
  days,
  deviceType,
}: MeetingRoomDeviceUsageQueryOptions = {}): UseQueryResult<MeetingRoomDeviceUsageSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot =
    async (): Promise<MeetingRoomDeviceUsageSnapshotResponse> => {
      const baseParams: Record<string, string> = {};

      if (days) baseParams.Days = days;
      if (deviceType) baseParams.DeviceType = deviceType;

      const endpoint = `${API_ENDPOINTS.MR_MEETING_ROOMS_DEVICES
        }?${new URLSearchParams(baseParams).toString()}`;

      return httpClient.get<MeetingRoomDeviceUsageSnapshotResponse>(endpoint);
    };

  return useQuery(['meetingRoomDeviceUsage', days, deviceType], fetchSnapshot);
}

export function useDeviceFiltersQuery(
  includeEnabledOnly?: string,
  includeFreeLicenses?: boolean
): UseQueryResult<DevicesFiltersResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesFilters = async (): Promise<DevicesFiltersResponse> => {
    const params = new URLSearchParams();

    params.append(
      'includeEnabledOnly',
      includeEnabledOnly ? `${includeEnabledOnly}` : 'true'
    );

    params.append(
      'includeFreeLicenses',
      includeFreeLicenses ? `${includeFreeLicenses}` : 'false'
    );
    return licensesClient.get<DevicesFiltersResponse>(
      `${API_ENDPOINTS.DEVICES_FILTERS}?${params.toString()}`
    );
  };

  return useQuery(
    ['licensesFilters', includeEnabledOnly, includeFreeLicenses],
    fetchLicensesFilters
  );
}


export function useDeviceInventorySummaryFilterQuery(
): UseQueryResult<DevicesSummaryFiltersResponse> {
  const client = useHttpClient();
  const fetchLicensesFilters = async (): Promise<DevicesSummaryFiltersResponse> => {
    return client.get<DevicesSummaryFiltersResponse>(
      `${API_ENDPOINTS.HARDWARE_INVENTORY_SUMMARY_FILTER}`
    );
  };

  return useQuery(
    ['deviceInventorySummaryFilters'],
    fetchLicensesFilters
  );
}

export function useGeneratedTrunksSnapshotQuery(): UseQueryResult<ConfiguredTrunksSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<ConfiguredTrunksSnapshotResponse> => {

    const endpoint = `${API_ENDPOINTS.GENERATED_TRUNKS_SNAPSHOT
      }`;

    return httpClient.get<ConfiguredTrunksSnapshotResponse>(endpoint);
  };

  return useQuery(['generatedTrunks'], fetchSnapshot);
}