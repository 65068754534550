import * as React from 'react';
import { IconProps } from './types';

export const MinusIcon: React.FC<IconProps & { withoutCircle?: boolean }> = ({
  size = 4,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.14286,
  classNames = '',
  withoutCircle = false
}) => {
  return (
    !withoutCircle ?
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
        fill="none"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
          d="M11 8H5M8 15.429A7.429 7.429 0 1 0 8 .572a7.429 7.429 0 0 0 0 14.857Z"
        />
      </svg> :
      <svg className={`w-${size} h-${size} stroke-current ${color} ${classNames}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3338_6627)">
          <path d="M12.8332 7L1.1665 7" stroke="#657482" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_3338_6627">
            <rect width="13.3333" height="13.3333" fill="white" transform="translate(0.333252 0.333374)" />
          </clipPath>
        </defs>
      </svg>
  );
};
