import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSecurityReportPreviewQuery } from '@hooks/reports';
import { SecurityV2ReportSecureScoreResponse } from '@hooks/reports/types';
import styles from '@pages/InsightsAndAnalytics/Security/MsSecureScore/styles.module.css';
import { createCellValue } from '@pages/InsightsAndAnalytics/Security/SharedMailboxes/utils';
import { LineChartTile, LineChartTypes, LineChartTickFormat } from "@pages/InsightsAndAnalytics/tiles/LineChartTile";
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { Table, LineChartIconUp, LineChartIcon, MinusIcon } from '@components/ui';
import { DashboardTiles, MAILBOXES_WITH_MOST_DELEGATES_TABLE_COLUMNS, SHARED_MAILBOXES_TABLE_COLUMNS } from '@common/constants';
import { formatDate, useTableData } from '@utils/index';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { createCellValueForMailboxesTab } from '../utils';

type Change = "up" | "down" | "stayed the same";

export const ReportMailboxesPage: React.FC = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const location = useLocation();
  const selectedTab = location.pathname.split('/').pop();

  const query = useSecurityReportPreviewQuery<SecurityV2ReportSecureScoreResponse>({ reportId, selectedTab });
  const items = query?.data?.items || [];

  const signIns = items.find((item) => item.type === DashboardTiles.SignInsToSharedMailboxes)?.parameters;
  const tableData = items.filter((item) => item.type === DashboardTiles.Table);

  const mostActiveSharedMailboxesTable = tableData[0]?.parameters;
  const mailboxesWithDelegatesTable = tableData[1]?.parameters;
  const autoForwardingRules = items.find((item) => item.type === DashboardTiles.MailboxesForwardingRules)?.parameters;
  const autoForwardingRulesByUser = items.find((item) => item.type === DashboardTiles.AutoForwardingByUserTypeMailboxes)?.parameters;
  const delegatedAccessMailboxes = items.find((item) => item.type === DashboardTiles.MailboxesWithDelegatedAccess)?.parameters;
  const accountsUsingDelegation = items.find((item) => item.type === DashboardTiles.AccountsUsingDelegationToAccessMailbox)?.parameters;
  //info
  const sharedMailboxesInfo = items.find((item) => item.type === DashboardTiles.SharedMailboxesSignInInfo)?.parameters;
  const autoForwardingRulesInfo = items.find((item) => item.type === DashboardTiles.AutoForwardingRulesAndDelegationInfo)?.parameters;

  const firstOfThePeriod = signIns?.data[0][0];
  const lastOfThePeriod =
  signIns?.data[0][signIns?.data[0]?.length - 1];

  const trendIconChange: Change = (
    firstOfThePeriod < lastOfThePeriod
      ? 'up'
      : firstOfThePeriod > lastOfThePeriod
      ? 'down'
      : 'stayed the same'
  ) as Change;

  const signInChange: Change = (
    sharedMailboxesInfo?.signInsPercentageChange > 0
        ? "up"
        : sharedMailboxesInfo?.signInsPercentageChange < 0
            ? "down" :
            sharedMailboxesInfo?.signInsPercentageChange === 0 ?
                "stayed the same" : ""
) as Change;

const autoForwardingRuleChange: Change = (
  autoForwardingRulesInfo?.mailboxesForwardingRulesPercentageChange === null ? "" :
      autoForwardingRulesInfo?.mailboxesForwardingRulesPercentageChange
          ? "up"
          : "down"
) as Change;


  const getTrendSubtitleText = (change: string) => {
    if (change === "up")
        return "increased"
    if (change === "down")
        return "decreased"
    if (change === "stayed the same")
        return "remained the same"
    return ""
}

const getDelegationAndForwardingSubtext = (mailboxChange: boolean, accountsChange: boolean) => {
  if (mailboxChange && accountsChange)
      return " because both the number of mailboxes with delegated access and the number of accounts using delegation to access mailboxes increased."
  if (!mailboxChange && !accountsChange)
      return " because both the number of mailboxes with delegated access and the number of accounts using delegation to access mailboxes decreased."
  if (mailboxChange)
      return " because the number of mailboxes with delegated access have increased."
  if (!mailboxChange)
      return " because the number of mailboxes with delegated access has decreased."
}

const autoForwardingRuleIncreased = accountsUsingDelegation?.percentage && accountsUsingDelegation?.percentage;

  const labels = signIns?.labels.map((item: string) => formatDate(item));

  return (
    <>
      <div className="mt-[20px] flex flex-col items-start gap-4">
        <div className="flex flex-row items-start text-24 md:text-28">
          <p>Shared mailboxes</p>
          <div className="ml-4 mt-2 flex flex-row items-start">
            {trendIconChange === 'up' && (
              <LineChartIconUp color="text-successColor-100" size="4" />
            )}
            {trendIconChange === 'down' && (
              <LineChartIcon size="4" color="text-errorColor-100" />
            )}
            {trendIconChange === 'stayed the same' && (
              <MinusIcon
                size="4"
                color="text-interfaceColor-100"
                withoutCircle={true}
                classNames="mt-[2px]"
              />
            )}

            <span
              className={`ml-2 flex items-center text-xs ${
                trendIconChange === 'up'
                  ? 'text-successColor-100'
                  : trendIconChange === 'down'
                  ? 'text-errorColor-100'
                  : 'text-interfaceColor-80'
              }`}
            >
              {trendIconChange === 'up'
                ? 'More secure'
                : trendIconChange === 'down'
                ? 'Less secure'
                : trendIconChange === 'stayed the same'
                ? 'The same'
                : ''}
            </span>
          </div>
        </div>
        <div className="mb-[5px] w-[70%] text-16 md:text-18">
          <p>
            {signInChange
              ? `Sign-ins to shared mailboxes ${getTrendSubtitleText(
                  signInChange
                )} this month compared with last month.`
              : 'Here is the activity for your shared mailboxes.'}
          </p>
        </div>
      </div>
      <div className={styles.pageGrid}>
        {signIns != null && (
          <LineChartTile
            classNames={'col-span-6 lg:col-span-6 xl:col-span-6'}
            sortData={[]}
            tooltipLabel={'sign ins'}
            showCompareDropdown={false}
            showAgainstDropdown={false}
            contextData={[]}
            headerTitle={'Sign ins to shared mailboxes'}
            isLoading={false}
            labels={labels || undefined}
            data={signIns?.data}
            compareLabel=""
            dropdownMenuItemClasses={'!w-[300px]'}
            dataSetTypes={[LineChartTypes.Dots]}
            dataSetColors={['#00CF6C']}
            showYTicks={true}
            showDateRange={true}
            showXTicks={true}
            isDotted={true}
            chartHeight={150}
            xTickFormat={LineChartTickFormat.Date}
            yTickFormat={LineChartTickFormat.Number}
          />
        )}
      </div>
      <div className={styles.pageGrid}>
        <div className="col-span-6 mt-2 lg:col-span-6 xl:col-span-6">
          <div className="bg-white md:mx-auto lg:rounded-[4px] lg:shadow-shadow-02">
            <div className="p-4 text-24">
              <span>Most active shared mailboxes</span>
            </div>

            <Table
              columns={SHARED_MAILBOXES_TABLE_COLUMNS}
              data={useTableData(
                {
                  isFetched: true,
                  data: { items: mostActiveSharedMailboxesTable?.data },
                },
                SHARED_MAILBOXES_TABLE_COLUMNS,
                createCellValue,
                true
              )()}
              query={undefined}
              isLoading={false}
              totalCount={10}
              currentPage={1}
              totalPages={1}
              setPageNumber={() => 1}
              handleSelect={() => null}
              isFirstColumnSticky={false}
              isGrayHeader={true}
              showPagination={true}
            />
          </div>
        </div>
      </div>
      <div className="mt-[20px] flex flex-col items-start gap-4">
        <div className="flex flex-row items-start text-24 md:text-28">
          <p>Auto-forwarding and delegation</p>
        </div>
        <div className="mb-[5px] w-[70%] text-16 md:text-18">
          <p>
            {autoForwardingRuleChange === null
              ? `Here are your mailboxes with auto-forwarding rules and delegated users.`
              : `The number of mailboxes with auto-forwarding rules went ${
                  autoForwardingRuleIncreased ? 'up.' : 'down.'
                }`}
          </p>
          <p>
            Security risks from mailbox delegation{' '}
            {delegatedAccessMailboxes?.isPercentIncreased
              ? 'increased'
              : 'decreased'}{' '}
            this month,
            {getDelegationAndForwardingSubtext(
              delegatedAccessMailboxes?.isPercentIncreased,
              accountsUsingDelegation?.isPercentIncreased
            )}
          </p>
        </div>
      </div>
      <div className={styles.pageGrid}>
        {autoForwardingRules != null && (
          <DoughnutChartTile
            classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
            headerTitle={'Mailboxes with auto-forwarding rules enabled'}
            dropdownMenuItemClasses={'!w-[300px]'}
            data={
              autoForwardingRules
                ? [autoForwardingRules?.enabled, autoForwardingRules?.disabled]
                : []
            }
            dataLabels={[
              `Auto-forwarding rules enabled`,
              `Auto-forwarding rules disabled`,
            ]}
            isLoading={false}
            contextData={[]}
            key={'Mailboxes with auto-forwarding rules'}
          />
        )}
        {autoForwardingRulesByUser != null && (
          <DoughnutChartTile
            classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
            headerTitle={'Auto-forwarding by user access type'}
            dropdownMenuItemClasses={'!w-[300px]'}
            data={
              autoForwardingRulesByUser
                ? [
                    autoForwardingRulesByUser?.internal,
                    autoForwardingRulesByUser?.external,
                  ]
                : []
            }
            dataLabels={[`Internal`, `External`]}
            isLoading={false}
            key={'Auto-forwarding by user access type'}
            contextData={[]}
          />
        )}
        {delegatedAccessMailboxes != null && (
          <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
            key={delegatedAccessMailboxes?.title}
            sortData={undefined}
            contextData={[]}
            headerTitle={delegatedAccessMailboxes?.title}
            isLoading={false}
            percentageLabel={''}
            data={
              [
                delegatedAccessMailboxes?.total,
                delegatedAccessMailboxes?.percentage,
              ] || []
            }
            isArrowColorReversed={true}
            bottomLabel="Mailboxes accessed by other users through delegation"
          />
        )}
        {accountsUsingDelegation != null && (
          <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
            key={accountsUsingDelegation?.title}
            sortData={undefined}
            contextData={[]}
            percentageLabel={''}
            headerTitle={accountsUsingDelegation?.title}
            isLoading={false}
            data={
              [
                accountsUsingDelegation?.total,
                accountsUsingDelegation?.percentage,
              ] || []
            }
            isPercentIncreased={accountsUsingDelegation?.isPercentIncreased}
            isArrowColorReversed={true}
          />
        )}
      </div>
      <div className="col-span-6 mt-2 lg:col-span-6 xl:col-span-6">
        <div className="bg-white md:mx-auto lg:rounded-[4px] lg:shadow-shadow-02">
          <div className="p-4 text-24">
            <span>Mailboxes with most delegates</span>
          </div>

          <Table
            columns={MAILBOXES_WITH_MOST_DELEGATES_TABLE_COLUMNS}
            data={useTableData(
              {
                isFetched: true,
                data: { items: mailboxesWithDelegatesTable?.data },
              },
              MAILBOXES_WITH_MOST_DELEGATES_TABLE_COLUMNS,
              createCellValueForMailboxesTab,
              true
            )()}
            query={undefined}
            isLoading={false}
            totalCount={10}
            currentPage={1}
            totalPages={1}
            setPageNumber={() => 1}
            handleSelect={() => null}
            isFirstColumnSticky={false}
            isGrayHeader={true}
            showPagination={true}
          />
        </div>
      </div>
    </>
  );
};
