import { TilesProps } from '@common/types';
import { useExternalAppsAccountLevel } from '@hooks/security';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

export const AccessIntrusivenessAppsAccountLevel: React.FC<TilesProps> = ({
  classNames,
}) => {
  const { data, isLoading } = useExternalAppsAccountLevel();

  return (
    <DoughnutChartTile
      classNames={classNames ? classNames : 'col-span-3 lg:col-auto'}
      contextData={[]}
      sortData={[]}
      headerTitle={'Intrusiveness: apps with account-level access'}
      isLoading={isLoading}
      data={
        data
          ? [data?.snapshot.High, data?.snapshot.Medium, data?.snapshot.Low]
          : []
      }
      dataLabels={[
        `High: ${data?.snapshot.High}`,
        `Medium: ${data?.snapshot.Medium}`,
        `Low: ${data?.snapshot.Low}`,
      ]}
      colors={['#D62229', '#FFD466', '#00CF6C']}
      showBracketLabelValue={false}
      showMessageIfEmpty={false}
    />
  );
};
