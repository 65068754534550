import { OverlayPage } from '@components/partials';
import React from 'react';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import Tab, { Tab as TabType } from '@components/ui/Tab';
import { useIsMobile } from '@hooks/utils';

const DeviceInventoryPage: React.FC = () => {
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );
  const isMobile = useIsMobile();
  const tabs: TabType[] = [
    {
      path: '/insights-and-analytics/utilisation/device-inventory/summary',
      label: 'Devices',
    },
    {
      path: '/insights-and-analytics/utilisation/device-inventory/assignments',
      label: 'Device assignment',
    },
  ];

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Device inventory`}
    >
      <div className="mt-[-25px]">
        <Tab tabs={tabs} className={isMobile ? 'mb-[100px]' : 'mb-[0px]'} />
      </div>
    </OverlayPage>
  );
};

export default DeviceInventoryPage;
