import {
  CellBottomGroupPart,
  CellBottomPart,
} from '@components/ui/Table/utils';
import { ExternalAppResponse } from '@hooks/security/types';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { hasPermission } from '@utils/index';

import { Link } from 'react-router-dom';
import {
  CheckCircleIcon,
  CircleRightArrow,
  WarningCircleIcon,
} from '@components/ui/Icons';
import useModalStore from '@store/modal';

export const createCellValue = (
  property: keyof ExternalAppResponse,
  data: ExternalAppResponse[],
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const modalStore = useModalStore.getState();

  const seePeopleDetailsHasPermission = hasPermission(
    userRole,
    Permissions.SUPERADMIN_ADMIN
  );

  const handleOnClick = () => {
    modalStore.setIsDetailsModalOpen(true);
    modalStore.setDataDetailsModal({
      header: (
        <div className="flex flex-col gap-y-2 w-full">
          <div
            className={`permisssion-level-badge mb-1 flex h-[32px] w-fit items-center px-[14px] py-0 text-16 leading-[32px] permission-level-${data[index].permissionLevel}`}
          >
            <span>{data[index].permissionLevel} access</span>
          </div>
          <div className="flex flex-col w-full">
            <span className="text-36 text-interfaceColor-100">
              {data[index].applicationName || '-'}
            </span></div>
          <div className="flex flex-row flex-wrap items-center gap-x-4 gap-y-0">
            <Link
              target="_blank"
              to={data[index]['appUrl']}
              className="text-16 text-interfaceColor-100 underline"
            >
              {data[index]['appUrl']}
            </Link>
            <span className="h-4 w-[1px] bg-interfaceColor-40"></span>

            <span className="text-16 text-interfaceColor-100">
              {data[index].publisherName || '-'}
            </span>
            <span>
              {data[index]['publisherVerification'] === 'Verified' ? (
                <div className="flex items-center gap-2">
                  <CheckCircleIcon
                    color="text-primaryBrandColor-150"
                    size="4"
                  />
                  <span className="text-16 text-interfaceColor-80">
                    Verified publisher
                  </span>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <WarningCircleIcon
                    color="text-warningColor-150"
                    classNames="h-4 w-4 mr-1"
                  ></WarningCircleIcon>
                  <span className="text-16 text-interfaceColor-80">
                    Unverified publisher
                  </span>
                </div>
              )}
            </span>
          </div>
        </div>
      ),
      body: (
        <div className="flex flex-col">
          <span className="mb-3 text-20 text-interfaceColor-100">
            This app has {data[index].permissions.length} permissions:
          </span>
          <div className="flex max-h-72 flex-row flex-wrap items-center gap-2 overflow-y-auto">
            {data[index].permissions.map((permission, index) => (
              <span
                key={index}
                className="bg-interfaceColor-15 px-3 py-2 text-16"
              >
                {permission}
              </span>
            ))}
          </div>
        </div>
      ),
    });
  };

  switch (property) {
    case 'userFullName':
      return (
        <>
          <span className="flex items-center">
            {value}{' '}
            {seePeopleDetailsHasPermission && (
              <Link
                className="ml-2 mt-[3px] cursor-pointer"
                to={`/accounts/accounts/account/${data[index]['accountId']}`}
              >
                <CircleRightArrow size="4" classNames="mt-[0px]" />
              </Link>
            )}
          </span>
          {!isMobile && (
            <CellBottomPart data={data[index]['userEmailAddress']} />
          )}
        </>
      );
    case 'applicationName':
      return (
        <>
          <div className="flex items-center gap-2">
            <span>{value || 'No data available'}</span>{' '}
            <span className="cursor-pointer" onClick={handleOnClick}>
              <CircleRightArrow size="4" classNames="mt-[2px]" />
            </span>
          </div>
          <div className="flex flex-col">
            <Link
              target="_blank"
              to={data[index]['appUrl']}
              className="text-14 tracking-wide text-interfaceColor-80"
            >
              {data[index]['appUrl']}
            </Link>
          </div>
        </>
      );
    case 'publisherName':
      return (
        <div className="flex flex-col">
          <span>{value || 'No data available'}</span>
          <div className="flex">
            {data[index]['publisherVerification'] === 'Verified' ? (
              <div className="flex items-center gap-2">
                <CheckCircleIcon color="text-primaryBrandColor-150" size="4" />
                <span>Verified</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <WarningCircleIcon
                  color="text-warningColor-150"
                  classNames="h-4 w-4 mr-1"
                ></WarningCircleIcon>
                <span>Unverified</span>
              </div>
            )}
          </div>
        </div>
      );
    case 'permissionLevel':
      return (
        <div className={`permisssion-level-badge permission-level-${value}`}>
          <span>{value}</span>
        </div>
      );
    case 'groups':
      return (
        <>
          <span>{value?.[0] ?? 'Unassigned'}</span>
          {data[index]['groups'].length - 1 > 0 && (
            <CellBottomGroupPart count={data[index]['groups'].length - 1} />
          )}
        </>
      );
    case 'locationName':
      return (
        <>
          <span>{value ?? 'Unassigned'}</span>
        </>
      );
  }
};
