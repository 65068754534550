import { TilesProps } from '@common/types';
import { useOutdatedOSUserTypesSnapshotQuery } from '@hooks/outdatedData/OutdatedOperatingSystems';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
    days: string;
    groupList?: string[];
    OSType?: string;
    accountType?: string;
    locationList?: string[];
  };
  
export const OutdatedOSUsers: React.FC<TilesProps> = ({
    params,
    classNames,
}) => {
    const [parameters, setParameters] = useState<Params>(JSON.parse(params));

    // data hook
    const {
        data: outdatedOSUsers,
        isLoading: isOutdatedOSUsersLoading,
        refetch
    } = useOutdatedOSUserTypesSnapshotQuery(parameters);

    useEffect(() => {
        setParameters(JSON.parse(params));
        return () => {
          refetch();
        };
      }, [params, refetch]);
    
    return (
        <DoughnutChartTile
            classNames={
                classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
            }
            contextData={[]}
            sortData={[]}
            headerTitle={'Users with outdated OSs'}
            dropdownMenuItemClasses={'!w-[300px]'}
            isLoading={isOutdatedOSUsersLoading}
            data={
                outdatedOSUsers
                    ? [
                        outdatedOSUsers.snapshot?.Internal || 0,
                        outdatedOSUsers.snapshot?.External || 0
                    ]
                    : []
            }
            dataLabels={
                [`Internal users: ${outdatedOSUsers?.snapshot?.Internal || 0}`,
                `External users: ${outdatedOSUsers?.snapshot?.External || 0}`,
                ]}
            showBracketLabelValue={false}
            showMessageIfEmpty={false}
        />
    );
};
