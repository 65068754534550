import {
    DEFAULT_PAGE_SIZE,
    FAILED_LOGINS_SORT_BY,
    FAILED_LOGIN_ACCOUNTS_TABLE_COLUMNS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';

import { PeopleSortBy } from '@hooks/people/types';
import { useFailedLoginAccountsFiltersQuery, useFailedLoginAccountsPreviewQuery } from '@hooks/security';
import { usePagination } from '@hooks/utils/pagination';
import { Location } from '@hooks/locations/types';

import { formatDate, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { useLocation } from 'react-router-dom';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import { Modal } from '@components/ui';
import useModalStore from '@store/modal';
import { createCellValue } from './utils';
import { addGroupsToFilter, addLocationsToFilter, addSelectedDataToFilter, 
    getCountryFilterAsQueryParam, getGroupOrLocationFilterAsQueryParam, getStatusFilterAsQueryParam } from './helper';

const pageSize = DEFAULT_PAGE_SIZE;

const FailedLoginAccountsPage: React.FC = () => {
    const days = new URLSearchParams(useLocation().search).get("days") || "30";
    const {
        setIsDetailsModalOpen,
        setDataDetailsModal,
        isDetailsModalOpen,
        dataDetailsModal,
    } = useModalStore();

    const [search, setSearch] = useState('');
    const { pageNumber, setPageNumber } = usePagination();
    const [locationIds, setLocationIds] = useState<string[]>([]);
    const [groupIds, setGroupIds] = useState<string[]>([]);

    const [queryParams, setQueryParams] = useQueryParams({
        search: withDefault(StringParam, ''),
        sortBy: withDefault(StringParam, 'numberoffailedlogins'),
        order: withDefault(StringParam, 'desc'),
        filter: withDefault(StringParam, ''),
    });

    const allLocationIds: string[] = [];
    const groupsIDs: string[] = [];
    const locationFilerOptions: IOption[] = [];
    const groupFilerOptions: ExtendedOption[] = [];
    const transformedFilterData: FilterData[] = [];

    //filters
    const { data } = useFailedLoginAccountsFiltersQuery(days);
    const { data: locationsData, isFetched: locationDataFetched } =
        useLocationsQuery();
    const { data: groupsData, isFetched: groupsDataFetched } =
        useGroupsFilterQuery();

    if (data) {
        addSelectedDataToFilter(data, transformedFilterData);
    }
    if (locationDataFetched && locationsData)
        addLocationsToFilter(locationsData, locationFilerOptions,
            allLocationIds, transformedFilterData);

    if (groupsDataFetched && groupsData)
        addGroupsToFilter(groupsData, groupFilerOptions, transformedFilterData);

    useEffect(() => {
        locationsData?.forEach((location: Location) => {
            locationFilerOptions.push({ label: location.name, value: location.id });
            allLocationIds.push(location.id);
        });
        setLocationIds(allLocationIds);
    }, [locationsData]);

    useEffect(() => {
        const nested = (element: any) => {
            element.subGroups.forEach((subElement: any) => {
                groupsIDs.push(subElement.id);
                if (subElement.subGroups && subElement.subGroups.length > 0) {
                    nested(subElement);
                }
            });
        };

        groupsData?.forEach((element: any) => {
            groupsIDs.push(element.id);
            if (element.subGroups && element.subGroups.length > 0) {
                nested(element);
            }
        });

        setGroupIds(groupsIDs);
    }, [groupsData]);

    const filterValues = queryParams.filter.split(',');

    // table
    const query = useFailedLoginAccountsPreviewQuery({
        pageSize,
        pageNumber,
        searchTerm: search,
        sortBy: queryParams.sortBy as PeopleSortBy,
        order: queryParams.order as OrderBy,
        days,
        locationList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, locationIds),
        groupList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, groupIds),
        status: getStatusFilterAsQueryParam(data?.status.value, filterValues, "status"),
        mfaStatus: getStatusFilterAsQueryParam(data?.mfaStatus.value, filterValues, "mfaStatus"),
        countries: getCountryFilterAsQueryParam(data?.countries.value, filterValues),
    });

    const getTableData = useTableData(
        query,
        FAILED_LOGIN_ACCOUNTS_TABLE_COLUMNS,
        createCellValue
    );

    const [shouldDownload, setShouldDownload] = useState(false);
    const { refetch: downloadCsv } = useDownloadCsv({
        shouldDownload,
        endpoint: `${API_ENDPOINTS.FAILED_LOGIN_ACCOUNTS_EXPORT}`,
        params: {
            pageSize,
            pageNumber,
            searchTerm: search,
            sortBy: queryParams.sortBy as PeopleSortBy,
            order: queryParams.order as OrderBy,
            days,
            locationList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, locationIds),
            groupList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, groupIds),
            accountStatus: getStatusFilterAsQueryParam(data?.status.value, filterValues, "status"),
            mfaStatus: getStatusFilterAsQueryParam(data?.mfaStatus.value, filterValues, "mfaStatus"),
            countries: getCountryFilterAsQueryParam(data?.countries.value, filterValues),
        },
        filename: `Clevr360_Faled_login_accounts_data_${formatDate(
            new Date().toString()
        )}.csv`,
    });

    const handleDownloadClick = () => {
        setShouldDownload(true);
        downloadCsv();
        setShouldDownload(false);
    };

    return (
        <>
            <div className="mt-2">
                <TableWrapper
                    search={search}
                    setSearch={setSearch}
                    searchPlaceholder={'Search by account, location, group, source country'}
                    columns={FAILED_LOGIN_ACCOUNTS_TABLE_COLUMNS}
                    data={getTableData()}
                    sortData={FAILED_LOGINS_SORT_BY}
                    searchKey="search"
                    query={query}
                    filterData={transformedFilterData}
                    refetchQuery={query.refetch}
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                    setPageNumber={setPageNumber}
                    isCollapsable={true}
                    floatingFilterButton={false}
                    hasFilterBanner={false}
                    isLicensePage={true}
                    sliceColumns={2}
                    searchCountStatsLabel="accounts"
                    isDownloadAvailable={true}
                    filterHeaderText="Filter failed login data"
                    handleDownload={handleDownloadClick}
                    displayAsDropdownFilterItems={["Country"]}
                    noResultTitle={search ? 'No results matching your search' 
                        : 'There are no accounts with failed logins identified on your Microsoft 365 service'}
                      noResultSubtitle={search ? 'Try a less specific search.' : ''}
                />
            </div>
            <Modal
                headerTxt={dataDetailsModal.header}
                isOpen={isDetailsModalOpen}
                size={'medium'}
                showCloseBtn={true}
                onClose={() => {
                    setIsDetailsModalOpen(false);
                    setDataDetailsModal({ body: null, header: null });
                }}
                contentClassName="bg-white"
                actionButtons={<></>}
            >
                <div className="w-full">{dataDetailsModal.body}</div>
            </Modal></>
    );
};

export default FailedLoginAccountsPage;
