import {
    CellBottomGroupPart,
    CellBottomPart,
    CellLeftStatusPart,
} from '@components/ui/Table/utils';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { hasPermission } from '@utils/index';

import { Link } from 'react-router-dom';
import {
    CircleRightArrow,
} from '@components/ui/Icons';
import useModalStore from '@store/modal';
import { FAILED_LOGIN_ACCOUNTS_TABLE_COUNTRY_COLUMNS } from '@common/constants';
import { Table } from '@components/ui';

export const createCellValue = (
    property: string,
    data: any,
    index: number,
    isMobile: boolean,
    setPageNumber: () => void,
    isHidden = false
) => {
    const value = data[index][property];
    const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
    const modalStore = useModalStore.getState();

    const seeDetailsHasPermission = hasPermission(
        userRole,
        Permissions.ADMINS_SUPPORTUSER
    );

    const countryData = data[index]['country'] || data[index]['failedLoginsByCountry'] || {};
    const accountName = data[index]['fullName'] || "";
    const countries = Object.keys(countryData).map(key => ({
        name: key,
        loginAttempts: countryData[key]
    }))
    const countryNames = Object.keys(countryData || {});
    const groups = data[index]['groups'] || data[index]['group'];
    const handleOnClick = () => {
        modalStore.setIsDetailsModalOpen(true);
        modalStore.setDataDetailsModal({
            header: (
                <div className="flex flex-col gap-y-2 break-words">
                    <span className="text-36 text-interfaceColor-100">
                        Failed logins by country:
                    </span>
                    <span className="text-36 text-interfaceColor-100">
                        {accountName}
                    </span>
                </div>
            ),
            body: (
                <div className={'overflow-auto min-h-[280px] max-h-[400px]'}>
                    <Table
                        columns={FAILED_LOGIN_ACCOUNTS_TABLE_COUNTRY_COLUMNS}
                        data={countries}
                        isLoading={false}
                        totalCount={countries?.length}
                        currentPage={1}
                        totalPages={1}
                        setPageNumber={setPageNumber}
                        handleSelect={() => console.log("")}
                        isFirstColumnSticky={false}
                        isGrayHeader={true}
                    />
                </div>
            ),
        });
    };

    switch (property) {
        case 'fullName':
            return (
                <>
                    <span className="flex items-center">
                        {value}{' '}
                        {seeDetailsHasPermission && !isHidden && (
                            <Link
                                className="ml-2 mt-[3px] cursor-pointer"
                                to={`/accounts/accounts/account/${data[index]['accountId']}`}
                            >
                                <CircleRightArrow size="4" classNames="mt-[0px]" />
                            </Link>
                        )}
                    </span>
                    {!isMobile && (
                        <CellBottomPart data={data[index]['emailAddress'] || data[index]['accountEmail']} />
                    )}
                </>
            );
        case 'groups':
        case 'group':
            return (
                <>
                    <span>{value?.[0] ?? 'Unassigned'}</span>
                    {groups?.length - 1 > 0 && (
                        <CellBottomGroupPart count={groups?.length - 1} />
                    )}
                </>
            );
        case 'locationName':
            return (
                <>
                    <span>{value ?? 'Unassigned'}</span>
                </>
            );
        case 'status':
            {
                const status = typeof !!value === 'boolean' ? (value ? 'Enabled' : 'Disabled') : value
                return (
                    <>
                        <CellLeftStatusPart status={status} />
                        <span>{status}</span>
                    </>
                );
            }
        case 'mfaStatus': {
            const status = typeof !!value === 'boolean' ? (value ? 'Enabled' : 'Disabled') : value
            return (
                <>
                    <CellLeftStatusPart status={status} />
                    <span>{status}</span>
                </>
            );
        }
        case 'failedLoginsByCountry':
        case 'country':
            return (
                <div className="flex flex-row items-center gap-[5px]">
                    <div>
                        {
                            countryNames.length > 1 ? `${countryNames.length} countries` : countryNames[0]}

                    </div>
                    {countryNames.length > 1 && !isHidden && <div className="flex items-center gap-2">
                        <span onClick={handleOnClick}>
                            <CircleRightArrow size="4" classNames="mt-[2px] cursor-pointer" />
                        </span>
                    </div>}
                </div>
            );
        case 'numberOfFailedLogins':
            return (
                <>
                    <span>{value ?? 0}</span>
                </>
            );
        case 'default':
            return (
                <></>
            );
    }
};
