import {
  DEFAULT_PAGE_SIZE,
  EXTERNAL_TENANTWIDE_APPS_SORT_BY,
  EXTERNAL_TENANTWIDE_APPS_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { PeopleSortBy } from '@hooks/people/types';
import {
  useTenantwideAppsPreviewQuery,
} from '@hooks/security';
import { usePagination } from '@hooks/utils/pagination';

import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { createCellValue } from './utils';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { Modal } from '@components/ui/Modal';
import useModalStore from '@store/modal';

const pageSize = DEFAULT_PAGE_SIZE;

const RemoteTenantwideAppsPage: React.FC = () => {
  const {
    setIsDetailsModalOpen,
    setDataDetailsModal,
    isDetailsModalOpen,
    dataDetailsModal,
  } = useModalStore();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'permissionLevel'),
    order: withDefault(StringParam, 'desc'),
    filter: withDefault(StringParam, ''),
  });

  const PUBLISHER_VERIFICATION_FILTER = ['Verified', 'Unverified'];
  const PERMISSION_LEVELS_FILTER = ['High', 'Medium', 'Low'];

  const transformedFilterData: FilterData[] = [{
    label: 'Publisher verification',
    name: 'publisherVerification',
    singleSelect: false,
    options: [
      {
        label: PUBLISHER_VERIFICATION_FILTER[0],
        value: PUBLISHER_VERIFICATION_FILTER[0]
      },
      {
        label: PUBLISHER_VERIFICATION_FILTER[1],
        value: PUBLISHER_VERIFICATION_FILTER[1],
      },
    ],
  },
  {
    label: 'Access',
    name: 'access',
    singleSelect: false,
    options: [
      {
        label: PERMISSION_LEVELS_FILTER[0],
        value: PERMISSION_LEVELS_FILTER[0]
      },
      {
        label: PERMISSION_LEVELS_FILTER[1],
        value: PERMISSION_LEVELS_FILTER[1]
      }, {
        label: PERMISSION_LEVELS_FILTER[2],
        value: PERMISSION_LEVELS_FILTER[2]
      },

    ],
  }];

  // table
  const query = useTenantwideAppsPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    publisherVerification: queryParams.filter
      ? queryParams.filter
        .split(',')
        .filter((filter) => PUBLISHER_VERIFICATION_FILTER.includes(filter))
      : [],

    permissionLevels: queryParams.filter
      ? queryParams.filter
        .split(',')
        .filter((filter) => PERMISSION_LEVELS_FILTER.includes(filter))
      : [],
  });

  const getTableData = useTableData(
    query,
    EXTERNAL_TENANTWIDE_APPS_TABLE_COLUMNS,
    createCellValue
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.EXTERNAL_APPLICATIONS_TENANTWIDE_EXPORT}`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      publisherVerification: queryParams.filter
        ? queryParams.filter
          .split(',')
          .filter((filter) => PUBLISHER_VERIFICATION_FILTER.includes(filter))
        : [],

      permissionLevels: queryParams.filter
        ? queryParams.filter
          .split(',')
          .filter((filter) => PERMISSION_LEVELS_FILTER.includes(filter))
        : [],
    },
    filename: `Clevr360_Remote_access_tenantwide_apps_data_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <>
      <div className="mt-2">
        <TableWrapper
          search={search}
          setSearch={setSearch}
          searchPlaceholder={
            'Search by app name, publisher'
          }
          columns={EXTERNAL_TENANTWIDE_APPS_TABLE_COLUMNS}
          data={getTableData()}
          sortData={EXTERNAL_TENANTWIDE_APPS_SORT_BY}
          filterData={transformedFilterData}
          searchKey="search"
          query={query}
          refetchQuery={query.refetch}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          setPageNumber={setPageNumber}
          isCollapsable={false}
          isLicensePage={true}
          floatingFilterButton={false}
          hasFilterBanner={false}
          searchCountStatsLabel="external apps with tenant-wide access"
          subTitle="Apps in this table have been given access to all accounts in your organization."
          filterHeaderText="Filter remote access data"
          isDownloadAvailable={true}
          handleDownload={handleDownloadClick}
          sliceColumns={2}
          noResultTitle={search ? 'No results matching your search' 
            : 'There are no external applications identified on your Microsoft 365 service'}
          noResultSubtitle={search ? 'Try a less specific search.' : ''}
        />
      </div>

      <Modal
        headerTxt={dataDetailsModal.header}
        isOpen={isDetailsModalOpen}
        size={'large'}
        showCloseBtn={true}
        onClose={() => {
          setIsDetailsModalOpen(false);
          setDataDetailsModal({ body: null, header: null });
        }}
        contentClassName="bg-white"
        actionButtons={<></>}
      >
        <div className="w-full">{dataDetailsModal.body}</div>
      </Modal>
    </>
  );
};

export default RemoteTenantwideAppsPage;
