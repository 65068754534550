import {
  ALL,
  FREE_LICENSES,
  MONTHS,
  NotificationTypes,
  VENDORS,
  notificationsConfig,
} from '@common/constants';
import { ExtendedOption, IOption, QueryParams, TrendDataItem } from '@common/types';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { WarningCircleIcon } from '@components/ui';
import { GroupsResponse } from '@hooks/groups/types';
import { Notification } from '@hooks/notifications/types';
import { UserRoles, permissionConfig, Permissions } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils';
import { ServiceStatus } from '@store/servicesStore';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useCallback } from 'react';
import { Location as LocationType } from '@hooks/locations/types';

dayjs.extend(customParseFormat);

export const formatDate = (dateString: string): string => {
  if (!dateString) return 'No data available';
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-UK', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return formattedDate === 'Invalid Date' ? dateString : formattedDate;
};

export const formatLastUpdatedDate = (dateString: string): string => {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString().slice(-2);
  const hour = date.getUTCHours().toString().padStart(2, '0');
  const minute = date.getUTCMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} at ${hour}:${minute}`;
};

export const formatDateInUTC = (dateString: string): string => {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
};

export const formatDateAndDiff = (dateString: string): number => {
  if (!dateString) return 0;

  const currentDate = new Date();
  const inputDate = new Date(dateString);

  // Calculate the difference in milliseconds
  const timeDiff = currentDate.getTime() - inputDate.getTime();

  // Calculate the difference in days
  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

  const formattedDate = inputDate.toLocaleDateString('en-UK', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return formattedDate === 'Invalid Date' ? 0 : daysDiff;
};

export const isSingularOrPluralPeopleText = (
  itemLength: number,
  withItemLength = true
): string => {
  const personText = withItemLength ? `${itemLength} person` : 'person';
  const peopleText = withItemLength ? `${itemLength} people` : 'people';

  return itemLength === 1 ? personText : peopleText;
};

export const convertDecimalToHoursMinutes = (decimalHours: number): string => {
  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);
  return `${hours} hrs ${minutes} mins`.replace('0 hrs', '');
};

export const hasNestedArray = (arr: any[]): boolean => {
  return arr.some((element) => Array.isArray(element));
};

export const calculateTotalSeconds = (timeString: string): number => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const totalSeconds = hours * 3600 + minutes * 60;

  return totalSeconds;
};

export const convertMinutesToHours = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} hrs ${remainingMinutes} mins`.replace('0 hrs', '');
};

export const formatNumberWithSuffix = (value: number | undefined): string => {
  if (!value || value === 0) return '0';

  if (value < 10000) {
    return value.toLocaleString();
  }

  const suffixes = ['k', 'm', 'b', 't'];
  const numDigits = Math.floor(Math.log10(value) / 3);
  const roundedValue = (value / Math.pow(10, numDigits * 3)).toFixed(1);
  const formattedValue = roundedValue.endsWith('.0')
    ? roundedValue.slice(0, -2)
    : roundedValue;

  return `${formattedValue}${suffixes[numDigits - 1]}`;
};

export const getFilterValues = (
  queryParams: QueryParams,
  acceptableValues: string[]
): string[] => {
  return queryParams.filter
    ? queryParams.filter
      .split(',')
      .filter((filter) => acceptableValues.includes(filter))
    : [];
};

export const getFirstMatchedFilterValue = (
  queryParams: QueryParams,
  acceptableValues: string[]
): string => {
  const matchedValues = getFilterValues(queryParams, acceptableValues);
  return matchedValues.length > 0 ? matchedValues[0] : '';
};

export const findGroupById = (
  data: GroupsResponse[],
  id: string | number
): null | GroupsResponse => {
  if (!data || data.length === 0) {
    return null;
  }

  const currentGroup = data.find((g: GroupsResponse) => g.id === id);
  if (currentGroup) {
    return currentGroup;
  }

  for (const group of data) {
    if (group.subGroups && group.subGroups.length > 0) {
      const nestedGroup: GroupsResponse | null = findGroupById(
        group.subGroups,
        id
      );
      if (nestedGroup) {
        return nestedGroup;
      }
    }
  }

  return null;
};

export const calculateDeepestLevel = (group: GroupsResponse): number => {
  if (!group.subGroups || group.subGroups.length === 0) {
    return 1;
  }

  let deepestSubgroupLevel = 0;
  for (const subGroup of group.subGroups) {
    const subGroupLevel = calculateDeepestLevel(subGroup);
    if (subGroupLevel > deepestSubgroupLevel) {
      deepestSubgroupLevel = subGroupLevel;
    }
  }

  return deepestSubgroupLevel + 1;
};

export const calculateDateDifference = (dateString: string): number => {
  const [day, month, year] = dateString.split('/').map(Number);

  const inputDate: Date = new Date(year, month - 1, day);
  const currentDate: Date = new Date();

  const timeDifference: number = currentDate.getTime() - inputDate.getTime();

  const daysDifference: number = Math.floor(
    timeDifference / (1000 * 60 * 60 * 24)
  );

  return daysDifference;
};

export const getVendorName = (vendor: number | undefined) => {
  if (vendor === undefined) return;
  switch (vendor) {
    case 0:
      return 'Microsoft';
    case 1:
      return 'RingCentral';
    case 2:
      return 'Enhanced Security Insights';
    case 3:
      return 'Mitel';
    case 4:
      return 'Avaya';
    default:
      return 'Unknown Vendor';
  }
};

export const extractDate = (dateTime: string): string => {
  const dateObj = new Date(dateTime);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (dateObj.toDateString() === today.toDateString()) {
    return 'Today';
  }

  if (dateObj.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  }

  return `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
};

export const sortKeysByDate = (keys: string[]): string[] => {
  return keys.sort((a, b) => {
    if (a === 'Today') return -1;
    if (b === 'Today') return 1;
    if (a === 'Yesterday') return -1;
    if (b === 'Yesterday') return 1;
    return new Date(b).getTime() - new Date(a).getTime();
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (func: Function, wait: number) => {
  let timeout: any;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getVendorList = (vendor: string) =>
  vendor === ALL ? VENDORS : [vendor];

export const showLastUsedData = (days: number, hasNotUsedLabel = false) => {
  if (days < 30) return;
  const iconColor =
    days >= 90
      ? 'text-errorColor-100'
      : days >= 60
        ? 'text-warningColor-150'
        : 'text-infoColor-150';

  return (
    <p className="flex items-center">
      <WarningCircleIcon color={iconColor} classNames="h-4 w-4 mr-1" />
      <span className="text-14 text-interfaceColor-80">
        {hasNotUsedLabel
          ? `Hasn’t been used in last ${days} days`
          : `   Over ${days} ${days === 1 ? 'day' : 'days'} ago`}
      </span>
    </p>
  );
};

export const showLastUseLicense = (days: number, hasNotUsedLabel = false) => {
  const iconColor =
    days >= 90
      ? 'text-errorColor-100'
      : days >= 60
        ? 'text-warningColor-150'
        : 'text-infoColor-150';

  return (
    <p className="flex items-center">
      <WarningCircleIcon color={iconColor} classNames="h-4 w-4 mr-1" />
      <span className="text-14 text-interfaceColor-80">
        {days < 30
          ? hasNotUsedLabel
            ? 'Recently used'
            : 'Used recently'
          : hasNotUsedLabel
            ? `Hasn’t been used in last ${days} days`
            : `Over ${days} ${days === 1 ? 'day' : 'days'} ago`}
      </span>
    </p>
  );
};

// refactor and move to hooks file
export function useTableData(query: any, columns: any, fn: any, hideItem?: any) {
  const isMobile = useIsMobile();
  return useCallback(() => {
    if (query.isFetched) {
      const newData: any[] = [];

      (query.data?.items || []).forEach((item: any, index: number) => {
        const newRow: any = {};

        columns.forEach((i: any) => {
          const formatedValue = truncateValue(item[i.key]);
          if (i.isComplexCell) {
            newRow[i.key] = fn(
              i.key,
              query.data?.items,
              index,
              isMobile,
              formatedValue,
              hideItem
            );
          } else {
            newRow[i.key] = item[i.key];
          }
        });

        if (item['id']) {
          newRow['id'] = item['id'];
        }

        newData.push(newRow);
      });

      return newData;
    } else {
      return [];
    }
  }, [query.data?.items]);
}

export const truncateValue = (value: string): string => {
  if (value) {
    return value.length > 20 ? `${value.substring(0, 30)}...` : value;
  }
  return '';
};

export const checkIfDataSetIsEmpty = (data: (number | null)[][]): boolean => {
  return data && data.every((array) => array && array.length >= 0);
};

/**
 * @param {number} bytes - The size in bytes to be formatted.
 * @param {boolean} [shortSuffix=false] - Use short suffixes (e.g., 'KB' instead of 'Kilobytes').
 * @param {number} [decimalPoint=1] - The number of decimal points to include in the formatted size.
 * @returns {string[]} An array with two elements: the formatted size and the corresponding unit.
 */
export function formatBytes(
  bytes: number,
  shortSuffix = false,
  decimalPoint = 1
): string[] {
  if (bytes === 0 || isNaN(bytes)) return ['0', shortSuffix ? 'B' : 'Bytes'];

  const unit = 1024;
  const sizes = ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Terabytes'];
  const sizesShort = ['B', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(unit));

  return [
    (bytes / Math.pow(unit, i)).toFixed(decimalPoint),
    shortSuffix ? sizesShort[i] || '' : sizes[i] || '',
  ];
}

export const customDateConvert = (date: string) => {
  const [day, month] = date.split('/').map(Number);

  return `${MONTHS[month - 1 || 0].substring(0, 3)} ${day}`;
};

export const isNumberIncludingZero = (value: any): value is number => {
  return typeof value === 'number' && !isNaN(value);
};

export const formatSeconds = (seconds: number): string => {
  if (isNaN(seconds) || seconds < 0) {
    return '';
  }

  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += hours + 'hrs';
    if (remainingMinutes > 0) {
      formattedTime += ' ';
    }
  }

  if (remainingMinutes > 0) {
    formattedTime += remainingMinutes + 'mins';
    if (hours === 0 && remainingSeconds > 0) {
      formattedTime += ' ';
    }
  }

  if (remainingSeconds > 0 && hours === 0) {
    formattedTime += remainingSeconds + 's';
  }

  return formattedTime.trim();
};

export const getTrendData = (
  data: TrendDataItem[],
  requestedLength: number
): (number | null)[] => {
  if (data.length === 0) {
    return [];
  }

  const trendData = data.map((item: TrendDataItem) => item.value);

  if (trendData?.length < requestedLength) {
    const diff = requestedLength - trendData.length;
    const nullArray = Array(diff).fill(null);
    return nullArray.concat(trendData);
  }

  return trendData;
};

export const getTrendLabels = (
  data: TrendDataItem[],
  requestedLength: number
): string[] => {
  if (data.length === 0) {
    return [];
  }

  const labels = data.map((item: TrendDataItem) =>
    formatDate(item.date as string)
  );

  if (labels?.length < requestedLength) {
    const diff = requestedLength - labels.length;
    const spaceArray = Array(diff).fill('');
    return spaceArray.concat(labels);
  }
  return labels;
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getBannerMessage = (
  type: NotificationTypes,
  severity: string,
  value: string
): string => {
  const key = `${type}|${severity}`;
  const config = notificationsConfig.get(key);

  if (!config) {
    return '';
  }

  if (type === NotificationTypes.TypeEight || type === NotificationTypes.TypeTen)
    return config.message;

  if (config.message.includes('[value]')) {
    return config.message.replace('[value]', value);
  } else {
    return `${config.message} ${value}`;
  }
};
export const getVariant = (item: 'High' | 'Medium' | 'Low' | 'Information') => {
  switch (item) {
    case 'High':
      return 'error';
    case 'Medium':
    case 'Low':
      return 'warning';
    default:
      return 'info';
  }
};

const severityOrder = {
  High: 1,
  Medium: 2,
  Low: 3,
  Information: 4,
};

export const groupAndSortNotifications = (
  notifications: Notification[]
): Record<string, Notification[]> => {
  const grouped: Record<string, Notification[]> = {};

  notifications.forEach((notification) => {
    const date = extractDate(notification.createdAt);
    if (!grouped[date]) {
      grouped[date] = [];
    }
    grouped[date].push(notification);
  });

  const sortedKeys = Object.keys(grouped).sort(
    (a, b) => dayjs(b).unix() - dayjs(a).unix()
  );

  const sorted: Record<string, Notification[]> = {};
  sortedKeys.forEach((key) => {
    sorted[key] = grouped[key].sort((a, b) => {
      return severityOrder[a.severity] - severityOrder[b.severity];
    });
  });

  return sorted;
};

export const isWholeNumber = (number: number) => number % 1 === 0;

export const hasPermission = (
  userRole: UserRoles,
  permissionType: Permissions
): boolean => {
  return permissionConfig[permissionType].includes(userRole);
};

export const shortenYearInDate = (date: string) => {
  const originalDate = dayjs(date, 'DD/MM/YYYY');

  return originalDate.isValid() ? originalDate.format('DD/MM/YY') : null;
};

export const isUserIdInGroupViewers = (userId: string, groupViewers: any[]) => {
  return groupViewers.some((viewer) => viewer.userId === userId);
};

export const formatLicensesFilter = (
  filters: string,
  locationIds?: string[],
  groupIds?: string[]
) => {
  if (locationIds && groupIds) {
    return filters
      ? filters
        .split(',')
        .filter(
          (filter) =>
            !VENDORS.includes(filter as any) &&
            !FREE_LICENSES.includes(filter) &&
            !locationIds.includes(filter) &&
            !groupIds.includes(filter)
        )
      : [];
  } else {
    return filters
      ? filters
        .split(',')
        .filter(
          (filter) =>
            !VENDORS.includes(filter as any) &&
            !FREE_LICENSES.includes(filter)
        )
      : [];
  }
};

export const getSearchQueryParams = (
  options: Record<string, string | number | boolean | string[] | undefined>
) => {
  const params = new URLSearchParams();

  Object.entries(options).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (item !== undefined && item !== null && item !== '') {
          params.append(key, item);
        }
      });
    } else if (
      value !== undefined &&
      value !== null &&
      value.toString() !== ''
    ) {
      params.set(key, value.toString());
    }
  });

  return params;
};
export const truncateString = (text: string, maxChars: number) =>
  text.length <= maxChars ? text : `${text.substring(0, maxChars)}...`

// page filter helpers
export const addLocationDataToPageFilter = (locationsData: LocationType[], filterOptions: IOption[],
  locationIds: string[], filter: FilterData[]) => {
  locationsData?.forEach((location) => {
    filterOptions.push({ label: location.name, value: location.id });
    locationIds.push(location.id);
  });

  filter.push({
    label: 'Location',
    name: 'location',
    singleSelect: false,
    options: filterOptions,
  });
}

export const addGroupDataToPageFilter = (groupsData: GroupsResponse[],
  filterOptions: ExtendedOption[], filter: FilterData[]) => {
  groupsData?.forEach((group: GroupsResponse) => {
    filterOptions.push({
      name: group.name,
      value: group.id,
      parentId: group.parentId,
      subGroups: group.subGroups,
      id: group.id,
      label: group.name,
    });
  });

  filter.push({
    label: 'Groups',
    name: 'groups',
    singleSelect: false,
    options: filterOptions,
  });
}

export const addSelectedDataToFilter = (data: any, filterData: FilterData[], mapLabels?: (key: string) => string) => {
  Object.keys(data).forEach((key) => {
    let filterOptions: IOption[] = [];
    if (Array.isArray(data[key])) {
      filterOptions = (data[key] as string[])?.map((item) => ({
        label: item,
        value: item,
      }));
    }
    filterData.push({
      label: (mapLabels && mapLabels(key)) || mapFilterDisplayNames(key),
      name: key,
      singleSelect: false,
      options: filterOptions,
    });
  });
}

const mapFilterDisplayNames = (label: string) => {
  return label?.replace(/(?<!^)([A-Z])/g, ' $1');
}

export const getGroupOrLocationFilterAsQueryParam =
  (filter: string | undefined, ids: string[]
  ) => {
    return filter ? filter.split(',').filter((item) =>
      ids.includes(item)) : []
  }

export const isHelixDataVisible = (helixStatus: ServiceStatus | undefined,
  type: 'dashboard' | 'securityTab' = 'securityTab'): boolean => {
  const allowedStatusList = [ServiceStatus.Active, ServiceStatus.Inactive];
  if (type === 'securityTab') allowedStatusList.push(ServiceStatus.Disconnected)
  return helixStatus !== undefined ? allowedStatusList.includes(helixStatus) : false;
}

export const isTraditionalCommsDataVisible = (status: ServiceStatus | undefined): boolean => {
  const allowedStatusList = [ServiceStatus.Active, ServiceStatus.Inactive, ServiceStatus.Disconnected];
  return status !== undefined ? allowedStatusList.includes(status) : false;
}

export const formatNumber = (percentage: any): string => {
  return percentage
    ? isWholeNumber(percentage)
      ? `${Math.round(percentage)}%`
      : `${percentage?.toFixed(2)}%`
    : '0%';
}
