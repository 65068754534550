import { CellAction, CellBottomPart } from '@components/ui/Table/utils';
import { SuspiciousActivityAccounts } from '@hooks/security/types';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { formatDate, hasPermission } from '@utils/index';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { CircleRightArrow } from '@components/ui/Icons';

export const createCellValue = (
  property: keyof any,
  data: any[],
  index: number,
  isMobile: boolean,
  formattedValue: string,
  isHiddenProperty = false
) => {
  const value = data[index][property];

  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const seePeopleDetailsHasPermission = hasPermission(
    userRole,
    Permissions.SUPERADMIN_ADMIN
  );

  switch (property) {
    case 'fullName':
      return (
        <>
          <span className="flex items-center">
            {value as string}{' '}
            {seePeopleDetailsHasPermission && !isHiddenProperty && (
              <Link
                className="ml-2 mt-[3px] cursor-pointer"
                to={`/accounts/accounts/account/${data[index]['accountId']}`}
              >
                <CircleRightArrow size="4" />
              </Link>
            )}
          </span>
          {!isMobile && <CellBottomPart data={data[index]['emailAddress'] || data[index]['email']} />}
        </>
      );
    case 'eventTypes': {
      const events = data[index]['eventTypes'];
      return (
        isHiddenProperty && (
          <>
            <span>
              {' '}
              {events.length == 1
                ? events[0].eventType
                : `${events.length} types`}
            </span>
            {events.length > 1 && (
              <div className="flex flex-row items-center">
                <CellBottomPart data={`View detail`} />
                <CellAction index={index} isVideoFormat={true} />
              </div>
            )}
          </>
        )
      );
    }
    case 'eventCounts':
    case 'eventCount':
      return (
        <>
          <span>{value as string}</span>
        </>
      );
    case 'latestEventDateTime':
    case 'dateTime':
      return typeof value === 'string' ? (
        <>
          <span>{formatDate(value)}</span>
          <CellBottomPart data={dayjs(value).format('HH:mm:ss')} />
        </>
      ) : (
        <span></span>
      );
  }
};
