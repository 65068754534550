import React, { RefObject, useEffect, useRef } from 'react';
import { DEBOUNCE_TIME_MS } from '@common/constants';
import { useDebouncedCallback } from 'use-debounce';
import { QueryParams, SetQueryParams } from '@pages/PeopleAndGroups/types';
import { CloseCircleIcon, SearchIcon } from '@components/ui/Icons';
import { Input } from '@components/ui';
import styles from './styles.module.css';
import { BaseSetQueryParams } from '@store/queryParamsStore';
import useUIStore from '@store/uiStore';

type SearchProps = {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  queryParams: QueryParams;
  setQueryParams: SetQueryParams;
  setQueryParamsStore?: BaseSetQueryParams;
  searchKey: string;
  setPageNumber: (pageNumber: number) => void;
  searchTag?: string;
  searchPlaceholder?: string;
};

export const Search: React.FC<SearchProps> = ({
  search,
  setSearch,
  queryParams,
  setQueryParams,
  setQueryParamsStore,
  setPageNumber,
  searchKey,
  searchTag = '',
  searchPlaceholder = 'Search term',
}) => {
  const searchInputRef: RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const searchWrapperRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);
  const { isAtStickyPositions } = useUIStore();

  useEffect(() => {
    const searchWrapper = searchWrapperRef.current;
    const searchInput = searchInputRef.current;

    if (searchWrapper && searchInput) {
      searchWrapper.addEventListener('mouseenter', handleMouseEnter);
      searchWrapper.addEventListener('mouseleave', handleMouseLeave);
      searchInput.addEventListener('mouseenter', handleMouseEnter);
      searchInput.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (searchWrapper && searchInput) {
        searchWrapper.removeEventListener('mouseenter', handleMouseEnter);
        searchWrapper.removeEventListener('mouseleave', handleMouseLeave);
        searchInput.removeEventListener('mouseenter', handleMouseEnter);
        searchInput.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  const searchTermDebounced = useDebouncedCallback(() => {
    if (queryParams?.search?.length >= 3) {
      setSearch(queryParams?.search);
    }
    if (queryParams?.search?.length < search?.length) {
      setSearch(queryParams?.search);
    }
  }, DEBOUNCE_TIME_MS);

  const handleSearchChange = (value: string) => {
    setPageNumber(1);
    setQueryParams({ [searchKey]: value }, 'pushIn');
    searchTermDebounced();
    setQueryParamsStore && setQueryParamsStore('search', value);
  };

  const handleSearchClear = () => {
    setQueryParams({ [searchKey]: undefined }, 'pushIn');
    setSearch('');
    setQueryParamsStore && setQueryParamsStore('search', '');
  };

  const handleMouseEnter = () => {
    if (!searchInputRef.current?.classList.contains('bg-interfaceColor-10')) {
      searchInputRef.current?.classList.add('animate-dissolve');
    }
    if (
      !searchWrapperRef.current?.classList.contains('!bg-interfaceColor-10')
    ) {
      searchWrapperRef.current?.classList.add('animate-dissolve');
    }
  };

  const handleMouseLeave = () => {
    if (!searchInputRef.current?.classList.contains('bg-interfaceColor-10')) {
      searchInputRef.current?.classList.remove('animate-dissolve');
    }

    if (
      !searchWrapperRef.current?.classList.contains('!bg-interfaceColor-10')
    ) {
      searchWrapperRef.current?.classList.remove('animate-dissolve');
    }
  };

  const handleOnFocus = () => {
    if (!searchInputRef.current?.classList.add('bg-interfaceColor-10')) {
      searchInputRef.current?.classList.add('bg-interfaceColor-10');
    }

    if (!searchWrapperRef.current?.classList.add('!bg-interfaceColor-10')) {
      searchWrapperRef.current?.classList.add('!bg-interfaceColor-10');
    }
  };

  const handleOnBlur = () => {
    searchInputRef.current?.classList.remove('bg-interfaceColor-10');
    searchInputRef.current?.classList.remove('animate-dissolve');
    searchWrapperRef.current?.classList.remove('!bg-interfaceColor-10');
    searchWrapperRef.current?.classList.remove('animate-dissolve');
  };

  const searchWrapperClassName = `${styles.searchWrapper} ${
    isAtStickyPositions ? 'lg:rounded-tl-[4px]' : ''
  }`;

  return (
    <div ref={searchWrapperRef} className={searchWrapperClassName}>
      <SearchIcon />
      {searchTag && (
        <span className="... w-[8.375rem] truncate rounded-3xl bg-interfaceColor-20 px-2 py-[0.125rem] text-14 text-interfaceColor-100">
          {searchTag}
        </span>
      )}
      <Input
        className=" flex-grow"
        inputClassName={styles.searchInput}
        name="search"
        placeholder={searchPlaceholder}
        ref={searchInputRef}
        value={queryParams?.search}
        onChange={(event) => handleSearchChange(event.target.value)}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {queryParams?.search?.length > 0 && (
        <button
          className="h-6 w-6 cursor-pointer p-1"
          onClick={handleSearchClear}
        >
          <CloseCircleIcon />
        </button>
      )}
    </div>
  );
};
