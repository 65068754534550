import * as React from 'react';
import { IconProps } from './types';

export const ClipboardIcon: React.FC<IconProps> = ({
  color = 'text-interfaceColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M13.0371 9.78627L13.0371 1.89342C13.0371 1.64714 12.9393 1.41096 12.7651 1.23682C12.591 1.06267 12.3548 0.964843 12.1085 0.964843L4.21568 0.964844C3.96941 0.964844 3.73322 1.06267 3.55908 1.23682C3.38494 1.41096 3.28711 1.64714 3.28711 1.89342L3.28711 9.78627C3.28711 10.0325 3.38494 10.2687 3.55908 10.4429C3.73322 10.617 3.96941 10.7148 4.21568 10.7148L12.1085 10.7148C12.3548 10.7148 12.591 10.617 12.7651 10.4429C12.9393 10.2687 13.0371 10.0325 13.0371 9.78627Z"
        stroke="#282B2F"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2506 13.0361L1.89342 13.0361C1.64714 13.0361 1.41096 12.9383 1.23682 12.7642C1.06268 12.59 0.964844 12.3538 0.964844 12.1076L0.964843 3.75042"
        stroke="#282B2F"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
