import { useEffect, useState } from 'react';

import { TilesProps } from '@common/types';
import {
  LineChartTickFormat,
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import {
  useSuspiciousActivityEventsFiltersQuery,
  useSuspiciousActivityTrendQuery,
} from '@hooks/security';

type Params = {
  days: string;
  eventType: string;
};

export const SuspiciousActivityTrend: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
  isSnapshot,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.SuspiciousActivityTrend,
    parameters
  );
  const isOnDashboardPage = !!id;

  const { data, isLoading, refetch } =
    useSuspiciousActivityTrendQuery(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));

    return () => {
      refetch();
    };
  }, [params, refetch]);

  const labels = getTrendLabels(data?.trend || [], +parameters.days);

  const trendData = getTrendData(data?.trend || [], +parameters.days);

  const handleDaysDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const { data: filterData } = useSuspiciousActivityEventsFiltersQuery(parameters.days);

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      eventType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const suspiciousActivityTypeMenuItems: MenuItem[] = [
    {
      id: '0',
      label: 'All Types',
      value: '',
      action: () => handleDropdown(''),
    },
    ...Object.entries(filterData?.eventTypes.value || {}).map(
      ([key, label], index) => ({
        id: (index + 2).toString(),
        label: label as string,
        value: key,
        action: () => handleDropdown(key),
      })
    ),
  ];

  const dropdownMenuDays = (): MenuItem[] => [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDaysDropdownChange('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDaysDropdownChange('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDaysDropdownChange('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDaysDropdownChange('90'),
    },
  ];

  const itemTypesSelected = suspiciousActivityTypeMenuItems.find(
    (item) => item.value === parameters.eventType
  );

  const itemDaysSelected = dropdownMenuDays().find(
    (item) => item.value === parameters.days
  );

  const getContextMenu = (): MenuItem[] => {
    if (!isSnapshot) {
      return isOnDashboardPage ? removeMenuItem : addMenuItem;
    } else {
      return [];
    }
  };
  
  const missingHistoricalData = data ? data.trend.every(obj => obj.value === null) : false;

  return (
    parameters && (
      <LineChartTile
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-6 xl:col-span-4'
        }
        sortData={!isSnapshot ? suspiciousActivityTypeMenuItems : []}
        initialSelectedItem={itemTypesSelected}
        initialCompareSelectedItem={itemDaysSelected}
        legendLabels={[]}
        compareData={!isSnapshot ? dropdownMenuDays() : []}
        contextData={getContextMenu()}
        showCompareDropdown={!isSnapshot}
        showAgainstDropdown={!isSnapshot}
        tooltipLabel={['']}
        headerTitle={'Suspicious activity'}
        isLoading={isLoading}
        labels={labels}
        data={[trendData]}
        compareLabel=""
        showYTicks={true}
        showXTicks={true}
        xTickFormat={LineChartTickFormat.Date}
        isDotted={true}
        chartHeight={150}
        dropdownSortMenuItemClasses={'!w-[360px] !min-w-[360px] max-h-[300px]'}
        dropdownMenuItemClasses={'!w-[300px]'}
        dataSetTypes={[LineChartTypes.Dots]}
        dataSetColors={['#00CF6C']}
        buttonLabel={!isSnapshot ? 'Suspicious activity data' : ''}
        linkPath={ANALYTICS_NAVIGATION_MAP['suspiciousActivity']}
        handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
        showDateRange={true}
        adjustedPosition={{ left: '0px' }}
        emptyMessageTextAndSubtext={{
          Text: "No suspicious activity found",
          SubText: "When suspicious activity is recorded, you will see information here."
        }}
        missingHistoricalData={missingHistoricalData}
      />
    )
  );
};
