import { NoResults } from '@components/partials';
import { WelcomeModal } from '@components/partials/WelcomeModal';
import {
  ArrowRightIcon,
  Banner,
  Button,
  Card,
  Loader,
} from '@components/ui';
import { CloudClevrLogoIcon } from '@components/ui/Icons/CloudClevrLogoIcon';
import { useDashboardsQuery } from '@hooks/dashboard';
import {
  useDismissNotification,
  useNotificationsQuery,
} from '@hooks/notifications';
import { Notification } from '@hooks/notifications/types';
import { useIsMobile, useIsTablet } from '@hooks/utils';
import { GroupedNotifications } from '@pages/Notifications';
import useUIStore, { NavSource } from '@store/uiStore';
import {
  getBannerMessage,
  getVariant,
  groupAndSortNotifications,
  hasPermission,
  isHelixDataVisible,
  isTraditionalCommsDataVisible,
  sortKeysByDate,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './styles.module.css';
import { renderTile } from './utils';
import useAuthStore from '@store/authStore';
import { Permissions, UserRoles } from '@hooks/users/types';
import {
  EnhancedSecurityInsightsTiles,
  NotificationTypes,
  SecurityDashboardTiles,
  TraditionalCommsInsightsTiles,
  notificationsConfig,
} from '@common/constants';
import { useCurrentUserQuery } from '@hooks/users';
import {
  useConnectedServicesQuery,
} from '@hooks/connectedServices';

export const DashboardPage: React.FC = () => {

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { setUnreadNotificationCount } = useUIStore();
  const userData = useCurrentUserQuery();
  const [sortedNotifications, setSortedNotifications] =
    useState<GroupedNotifications>({});
  const [dismissedNotifications, setDismissedNotifications] = useState<
    string[]
  >([]);
  const navigate = useNavigate();
  const notificationsQuery = useNotificationsQuery();
  const [unReadNotifications, setUnReadNotifications] = useState<
    Notification[]
  >([]);
  const { setNavSource } = useUIStore();
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const configureHelixBtnHasPermission = hasPermission(
    userRole,
    Permissions.SUPERADMIN_SUPPORTUSER
  );

  const getTopNotifications = (n: number): Notification[] => {
    const topNotifications: Notification[] = [];
    for (const date in sortedNotifications) {
      if (topNotifications.length >= n) break;
      for (const notification of sortedNotifications[date]) {
        if (topNotifications.length >= n) break;
        if (!dismissedNotifications.includes(notification.id)) {
          topNotifications.push(notification);
        }
      }
    }
    return topNotifications;
  };

  useEffect(() => {
    setUnReadNotifications(getTopNotifications(3));
  }, [sortedNotifications, dismissedNotifications]);

  const dismissNotificationMutation = useDismissNotification();

  const handleDismiss = (notificationId: string) => {
    dismissNotificationMutation.mutate(notificationId, {
      onSuccess: () => {
        setDismissedNotifications((prev) => [...prev, notificationId]);
        notificationsQuery.refetch();
        setUnReadNotifications(getTopNotifications(3));
      },

      onError: (error) => {
        console.error('Failed to dismiss notification:', error);
      },
    });
  };

  const { data: dashboardData } = useDashboardsQuery();

  useEffect(() => {
    if (notificationsQuery.data) {
      setUnreadNotificationCount(
        notificationsQuery.data?.filter((notification) => !notification.isRead)
          .length
      );

      const groupedNotifications = groupAndSortNotifications(
        notificationsQuery.data?.filter((notification) => !notification.isRead)
      );

      const sortedKeys = sortKeysByDate(Object.keys(groupedNotifications));
      const orderedGroupedNotifications: GroupedNotifications = {};
      sortedKeys.forEach((key) => {
        orderedGroupedNotifications[key] = groupedNotifications[key];
      });

      setSortedNotifications(orderedGroupedNotifications);
    }
  }, [notificationsQuery.data]);

  const { data: connectedServices } = useConnectedServicesQuery();

  const helixIntegration =
    connectedServices?.find((item) => item.vendor === 2) || null;

  const isHelixVisible = isHelixDataVisible(helixIntegration?.status, 'dashboard');

  const traditionalCommsIntegration =
    connectedServices?.find((item) => item.vendor === 3 || item.vendor === 4) || null;

  const isTraditionalCommsVisible = isTraditionalCommsDataVisible(traditionalCommsIntegration?.status);

  const haveResults =
    !notificationsQuery.isLoading &&
    !notificationsQuery.isError &&
    !!notificationsQuery?.data?.length;

  if (notificationsQuery.isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {connectedServices?.find((item) => item.vendor === 0) &&
        !helixIntegration && configureHelixBtnHasPermission && (
          <Banner
            className="mb-4 max-w-full xl:max-w-[1440px]"
            message={
              <div className="flex flex-col justify-start gap-y-2">
                <p>
                  You’re eligible for free Enhanced Security Insights for your
                  Microsoft services: head to <b>Settings</b> to activate
                </p>

                <Button
                  variant="outlineLight"
                  size="medium"
                  className={'mt-3 w-fit text-interfaceColor-100 md:mt-0'}
                  onClick={() => {
                    navigate('/settings/manage-connected-services');
                  }}
                >
                  Go to Settings
                  <ArrowRightIcon
                    color="text-interfaceColor-100"
                    size="2"
                    classNames="ml-2 mt-[3px] text-interfaceColor-100"
                  />
                </Button>
              </div>
            }
            variant={'info'}
            closeable={false}
          />
        )}
      {!haveResults ? (
        <div className="mb-3 flex w-full max-w-full xl:max-w-[1440px]">
          <div className="w-full">
            <NoResults
              headerTxt="No notifications"
              contentTxt="You`re all caught up!"
            />
          </div>
        </div>
      ) : (
        <>
          <div className="mb-3 flex max-w-full flex-row justify-between xl:max-w-[1440px]">
            <p className="text-24 md:text-28">Your top priorities:</p>
            {!isMobile && unReadNotifications.length ? (
              <Button
                variant="default"
                size="small"
                type="button"
                onClick={() => navigate('/notifications-list')}
                style={{
                  boxShadow:
                    '0px 1px 8px 0px #282B2F14, 0px 1px 2px 0px #282B2F1F,  0px -4px 4px 0px #FFFFFF',
                }}
              >
                View all notifications ({notificationsQuery.data?.length})
              </Button>
            ) : null}
          </div>
          {!unReadNotifications.length ? (
            <Card classNames="!w-full flex !flex-row gap-2 items-center !text-16">
              <CloudClevrLogoIcon size="5" />
              <b>No notifications</b> <span>You’re all caught up!</span>
            </Card>
          ) : (
            <div className="w-full max-w-full xl:max-w-[1440px]">
              <div className="hidden xl:block">
                {unReadNotifications.map((item: Notification) => (
                  <Banner
                    key={item.id}
                    className="mb-3"
                    message={getBannerMessage(
                      item.type,
                      item.severity,
                      item.value
                    )}
                    variant={getVariant(item.severity)}
                    closeable={true}
                    onClose={() => handleDismiss(item.id)}
                    ctaBtnLabel={`${notificationsConfig.get(`${item.type}|${item.severity}`)
                      ?.btnLabel ?? ''
                      }`}
                    ctaBtnOnClick={() => {
                      if (item.type !== NotificationTypes.TypeOne) {
                        setNavSource(NavSource.DashboardNotification);
                      }
                      handleDismiss(item.id);
                      navigate(
                        notificationsConfig.get(
                          `${item.type}|${item.severity}`
                        )?.link ?? ''
                      );
                    }}
                  />
                ))}
              </div>

              <div className="block xl:hidden">
                <Swiper
                  className="mb-4"
                  spaceBetween={20}
                  centeredSlides={false}
                  slidesPerView={isTablet ? 2.5 : 1.5}
                  loopedSlides={unReadNotifications.length}
                  pagination={{ clickable: true }}
                  modules={[Navigation, Scrollbar]}
                >
                  {unReadNotifications.map((item: any) => (
                    <SwiperSlide key={item.id}>
                      <Banner
                        message={getBannerMessage(
                          item.type,
                          item.severity,
                          item.value
                        )}
                        variant={getVariant(item.severity)}
                        closeable={true}
                        onClose={() => handleDismiss(item.id)}
                        ctaBtnLabel={`${notificationsConfig.get(
                          `${item.type}|${item.severity}`
                        )?.btnLabel ?? ''
                          }`}
                        ctaBtnOnClick={() => {
                          if (item.type !== 1) {
                            setNavSource(NavSource.DashboardNotification);
                          }
                          handleDismiss(item.id);
                          navigate(
                            notificationsConfig.get(
                              `${item.type}|${item.severity}`
                            )?.link ?? ''
                          );
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              {isMobile && unReadNotifications.length && (
                <Button
                  variant="default"
                  size="small"
                  type="button"
                  onClick={() => navigate('/notifications-list')}
                  className="w-full"
                  style={{
                    boxShadow:
                      '0px 1px 8px 0px #282B2F14, 0px 1px 2px 0px #282B2F1F,  0px -4px 4px 0px #FFFFFF',
                  }}
                >
                  View all notifications ({notificationsQuery.data?.length})
                </Button>
              )}
            </div>
          )}
        </>
      )}

      <div>
        <p className="my-3 mt-8 text-28 text-interfaceColor-100">
          What`s happening now:
        </p>
      </div>
      {!dashboardData?.length && (
        <div className="mt-3">
          <NoResults
            headerTxt="See the insights you care about here"
            contentTxt="Click the icon in the top right corner of any chart or insight to add it to your dashboard."
          />
        </div>
      )}

      {/* render tiles */}
      <div className={styles.pageGrid}>
        {dashboardData &&
          dashboardData.map((item) => {
            if (
              userRole === UserRoles.STANDARD &&
              SecurityDashboardTiles.includes(item.type) ||
              (!isHelixVisible && EnhancedSecurityInsightsTiles.includes(item.type))
              || (!isTraditionalCommsVisible && TraditionalCommsInsightsTiles.includes(item.type))
            ) {
              return <></>;
            } else {
              return <>{renderTile(item)}</>;
            }
          })}
      </div>

      <WelcomeModal
        content={
          <>
            <p>
              {`We've populated your dashboard with some insights we think you'll be interested in, based on your role at ${userData?.data?.organizationName || 'your company'
                }.`}
            </p>
            <p className="mb-5 mt-2">
              As you click around Clevr360, you`ll find other insights you want
              to add to your dashboard. Just click the three dots in the top
              right of the tile you`re interested in and select{' '}
              <b>Add this to my dashboard</b>.
            </p>
          </>
        }
        btnText="Explore Clevr360"
        modalKey="DashboardModal"
        title={
          <span>
            Welcome to the Clevr360
          </span>
        }
      />
    </div>
  );
};
