import {
    DEFAULT_PAGE_SIZE,
    SUSPICIOUS_ACTIVITY_ACCOUNTS_SORT_BY,
    SUSPICIOUS_ACTIVITY_ACCOUNTS_TABLE_COLUMNS,
} from '@common/constants';
import { IOption, OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { PeopleSortBy } from '@hooks/people/types';
import {
    useSuspiciousActivityAccountsFiltersQuery,
    useSuspiciousActivityAccountsPreviewQuery,
} from '@hooks/security';
import { usePagination } from '@hooks/utils/pagination';
import { formatDate, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { useLocation } from 'react-router-dom';
import { createCellValue } from './utils';
import useTableStore from '@store/tableStore';

const pageSize = DEFAULT_PAGE_SIZE;

const SuspiciousActivityAccountsPage: React.FC = () => {
    const [queryParams, setQueryParams] = useQueryParams({
        days: withDefault(StringParam, '30'),
        search: withDefault(StringParam, ''),
        sortBy: withDefault(StringParam, 'eventcount'),
        order: withDefault(StringParam, 'desc'),
        filter: withDefault(StringParam, ''),
      });
    
      const days = queryParams.days; // Get the 'days' parameter from queryParams
    
      const transformedFilterData: FilterData[] = [];
    
      const [search, setSearch] = useState('');
      const { pageNumber, setPageNumber } = usePagination();
    
      const { data } = useSuspiciousActivityAccountsFiltersQuery(days);

        if (data) {
        Object.keys(data).forEach((key) => {
        let filterOptions: IOption[] = [];

        if (Array.isArray(data[key].value)) {
            filterOptions = (data[key].value as string[]).map((value) => ({
            label: value,
            value,
            }));
        } else if (typeof data[key].value === 'object') {
            filterOptions = Object.entries(data[key].value).map(
            ([value, label]) => ({ label: label as string, value })
            );
        }

        transformedFilterData.push({
            label: data[key].key.replace(/(?<!^)([A-Z])/g, ' $1'),
            name: key,
            singleSelect: false,
            options: filterOptions,
        });
        });
    }

    const filterValues = queryParams.filter.split(',');

    // table
    const query = useSuspiciousActivityAccountsPreviewQuery({
        pageSize,
        pageNumber,
        searchTerm: search,
        sortBy: queryParams.sortBy as PeopleSortBy,
        order: queryParams.order as OrderBy,
        days,
        eventTypes:
        typeof data?.eventTypes.value === 'object'
            ? filterValues.filter((value) =>
                Object.keys(data.eventTypes.value).includes(value)
            )
            : [],
    });

    const { setExpandedRows, setExpandedRowId } = useTableStore();

    useEffect(() => {
        if (query.data?.items) {
          setExpandedRowId(-1, false);
          setExpandedRows(
            query.data.items.map((account) => {
              return {
                ...account,
                eventTypes: account.eventTypes,
              };
            })
          );
        }
      }, [query.data, setExpandedRows]);
    
      const getTableData = useTableData(
        query,
        SUSPICIOUS_ACTIVITY_ACCOUNTS_TABLE_COLUMNS,
        createCellValue
      );
    
      const [shouldDownload, setShouldDownload] = useState(false);
      const { refetch: downloadCsv } = useDownloadCsv({
        shouldDownload,
        endpoint: `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_ACCOUNTS_EXPORT}`,
        params: {
          pageSize,
          pageNumber,
          searchTerm: search,
          sortBy: queryParams.sortBy as PeopleSortBy,
          order: queryParams.order as OrderBy,
          days,
          eventTypes:
            typeof data?.eventTypes.value === 'object'
              ? filterValues.filter((value) =>
                  Object.keys(data.eventTypes.value).includes(value)
                )
              : [],
        },
        filename: `Clevr360_Suspicious_activity_accounts_data_${formatDate(
          new Date().toString()
        )}.csv`,
      });
    
      const handleDownloadClick = () => {
        setShouldDownload(true);
        downloadCsv();
        setShouldDownload(false);
      };
    
      return (
        <div className="mt-2">
            <TableWrapper
                search={search}
                setSearch={setSearch}
                searchPlaceholder={
                    'Search by account, event type'
                }
                columns={SUSPICIOUS_ACTIVITY_ACCOUNTS_TABLE_COLUMNS}
                data={getTableData()}
                sortData={SUSPICIOUS_ACTIVITY_ACCOUNTS_SORT_BY}
                filterData={transformedFilterData}
                searchKey="search"
                query={query}
                refetchQuery={query.refetch}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                setPageNumber={setPageNumber}
                isLicensePage={true}
                floatingFilterButton={false}
                hasFilterBanner={false}
                searchCountStatsLabel="accounts"
                filterHeaderText="Filter suspicious activity"
                isDownloadAvailable={true}
                handleDownload={handleDownloadClick}
                sliceColumns={1}
                isCollapsable={true}
                hasExpandedItems={true}
                noResultTitle={search ? 'No results matching your search' 
                    : 'There is no suspicious activity identified on your Microsoft 365 service.'}
                noResultSubtitle={search ? 'Try a less specific search.' : ''}
            />
        </div>
      );
    };
    
    export default SuspiciousActivityAccountsPage;