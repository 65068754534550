import React, { useEffect, useState } from 'react';
import { ArrowRightIcon, Button, Checkbox, Modal } from '@components/ui';

import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import { Link } from 'react-router-dom';
import useAuthStore from '@store/authStore';
import { UserRoles } from '@hooks/users/types';
import useUIStore from '@store/uiStore';
import { useCheckTermsConditions } from '@hooks/users';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthError, InteractionStatus } from '@azure/msal-browser';
import { silentRequest } from '@api/msalConfig';
import { Variant } from '@components/ui/Toast';

export const TermsModal: React.FC = () => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const authStore = useAuthStore();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { setTermsAcknowledged, termsAcknowledged } = useUIStore();

  const modalSize = isTablet || isMobile ? 'small' : 'medium';
  const modalPosition = isMobile ? 'bottom' : 'default';

  const termsAndConditionsMutation = useCheckTermsConditions();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(
        (!authStore.isTermsAndCondtitionsGranted &&
          authStore.role === UserRoles.SUPERADMIN) ||
        ((authStore.role === UserRoles.ADMIN ||
          authStore.role === UserRoles.STANDARD) &&
          !termsAcknowledged &&
          !authStore.isTermsAndCondtitionsGranted)
      );
    }, 1000);

    return () => clearTimeout(timer);
  }, [
    authStore.isTermsAndCondtitionsGranted,
    authStore.role,
    termsAcknowledged,
  ]);

  const { instance, inProgress } = useMsal();

  const { addToast } = useUIStore();
  const isAuthenticated = useIsAuthenticated();

  const handleLogout = async () => {
    authStore.setIsAuthenticated(false);
    authStore.setToken(null);
    authStore.setUserRole(null);
    authStore.setHasSelectedProfile(null);

    try {
      if (isAuthenticated && inProgress === InteractionStatus.None) {
        await instance.acquireTokenSilent(silentRequest);
      }
    } catch (error) {
      console.error('Silent token acquisition failed:', error);
    }

    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.redirectUrl || 'http://localhost:3000',
      });
    } catch (error: unknown) {
      if (error instanceof AuthError && error.errorMessage) {
        addToast({ variant: Variant.Error, message: error.errorMessage });
      } else {
        addToast({
          variant: Variant.Error,
          message: 'An error occurred during logout.',
        });
      }
    }
  };

  return (
    <Modal
      headerTxt={'Before you go any further...'}
      modalClassName="h-full mt-0"
      isOpen={isOpen}
      size={modalSize}
      position={modalPosition}
      showCloseBtn={false}
      onClose={() => {
        console.log('close');
      }}
      isFullHeightMobile={false}
      closeOnClickOutside={false}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
            disabled={!acceptedTerms && authStore.role === UserRoles.SUPERADMIN}
            variant="primary"
            onClick={() => {
              if (authStore.role === UserRoles.SUPERADMIN) {
                termsAndConditionsMutation.mutateAsync().then(() => {
                  setIsOpen(false);
                });
              } else {
                setTermsAcknowledged(true);
                setIsOpen(false);
              }
            }}
          >
            {authStore.role === UserRoles.SUPERADMIN ? 'Continue' : 'Got it'}
          </Button>

          {authStore.role === UserRoles.SUPERADMIN && (
            <Button type="button" onClick={handleLogout} variant="dark">
              Logout
            </Button>
          )}
        </div>
      }
      contentClassName="flex flex-col gap-y-6 !items-start !justify-start bg-white"
    >
      {authStore.role === UserRoles.SUPERADMIN ? (
        <>
          <div>
            Before you get started with Clevr360, read our terms and conditions.
            Check the box if you’re happy to proceed.
          </div>
          <Link
            to="/terms-and-conditions"
            className="flex w-full items-center justify-between rounded-sm bg-interfaceColor-15 px-4 py-3"
          >
            <span className="font-semibold"> Clevr360 terms & conditions</span>
            <ArrowRightIcon
              size="3"
              color="text-primaryBrandColor-100"
              classNames={`text-primaryBrandColor-100`}
            />
          </Link>
          <div className="mb-6">
            <Checkbox
              checked={acceptedTerms}
              onChange={() => setAcceptedTerms(!acceptedTerms)}
              name="terms"
              label="I've read and agree to the terms & conditions."
            />
          </div>
        </>
      ) : (
        <>
          <p>
            Before you start using Clevr360, your organisation needs to accept
            our terms and conditions. The person who’s in charge of your
            Clevr360 account will do this when they log into Clevr360 for the
            first time.
          </p>
        </>
      )}
    </Modal>
  );
};
