import { TilesProps } from '@common/types';
import { useMailboxesForwardingAccountTypeSnapshotQuery } from '@hooks/security';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

export const MailboxesForwardingAccountType: React.FC<TilesProps> = ({
  classNames,
}) => {
  const { data, isLoading } = useMailboxesForwardingAccountTypeSnapshotQuery();

  return (
    <DoughnutChartTile
      classNames={classNames ? classNames : 'col-span-3 lg:col-auto'}
      contextData={[]}
      sortData={[]}
      headerTitle={'Auto-forwarding by user access type'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isLoading}
      data={data ? [data.snapshot.Internal, data?.snapshot.External] : []}
      dataLabels={[`Internal`, `External`]}
      buttonLabel={''}
      showMessageIfEmpty={false}
    />
  );
};
