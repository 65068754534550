import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useLicencesOverassignedTrendQuery } from '@hooks/licenses';
import { useCallback, useMemo, useState } from 'react';

import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';

type Params = {
  days: string;
  compareAgainst: string;
  vendorList: string[];
  licenseTypeList: string[];
};

export const OverassignedLicensesTrendOverTime: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.OverassignedLicensesTrendOverTime,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: licencesTrendData,
    isLoading: isLicencesUtilisationLoading,
    refetch,
  } = useLicencesOverassignedTrendQuery(parameters);

  const labels = getTrendLabels(
    licencesTrendData?.trend || [],
    +parameters.days
  );

  const compareAgainstTrendData = getTrendData(
    licencesTrendData?.compareAgainstTrend || [],
    +parameters.days
  );

  const trendData = getTrendData(
    licencesTrendData?.trend || [],
    +parameters.days
  );

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const handleCompareDropdown = useCallback(
    (value: string) => {
      const newParameters = {
        ...parameters,
        compareAgainst: value,
      };

      setParameters(newParameters);

      if (isOnDashboardPage) {
        useDashboardUpdateTileParamsMutation.mutate({
          params: JSON.stringify(newParameters),
          tileId: id,
        });

        refetch();
      }
    },
    [parameters, useDashboardUpdateTileParamsMutation, id, refetch]
  );

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdown('30'),
    },
    {
      id: '2',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdown('60'),
    },
    {
      id: '3',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdown('90'),
    },
  ];

  const compareMenuItems = useMemo(() => {
    return [
      {
        id: '1',
        label: `Previous ${parameters.days} days`,
        value: 'prev',
        action: () => handleCompareDropdown('prev'),
      },
      {
        id: '2',
        label: 'Same period last year',
        value: 'yoy',
        action: () => handleCompareDropdown('yoy'),
      },
    ];
  }, [parameters.days, handleCompareDropdown]);

  const selectedSortItem = sortMenuItems.find(
    (item) => item.value === parameters.days
  );
  const selectedCompareItem = compareMenuItems.find(
    (item) => item.value === parameters.compareAgainst
  );

  const legendLabels = [(selectedSortItem && selectedSortItem.label) || ''];

  return (
    parameters && (
      <LineChartTile
        sortData={sortMenuItems}
        initialSelectedItem={selectedSortItem}
        legendLabels={legendLabels}
        dataSetColors={['#00CF6C']}
        compareData={undefined}
        compareLabel=""
        dropdownMenuItemClasses={'!w-[300px]'}
        classNames={'col-span-6 md:col-span-6 xl:col-span-4'}
        tooltipLabel={'overassigned licenses'}
        headerTitle={'Redundant licenses: Trend over time'}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        isLoading={isLicencesUtilisationLoading}
        labels={labels}
        data={[trendData]}
        dataSetTypes={[LineChartTypes.Dots]}
      />
    )
  );
};
