import React from 'react';
import Tab, { Tab as TabType } from '../../../../components/ui/Tab';
import { useParams } from 'react-router-dom';
import { useReportPreviewQuery } from '@hooks/reports';
import { hasPermission } from '@utils/index';
import useAuthStore from '@store/authStore';
import { UserRoles, Permissions } from '@hooks/users/types';
import { DownloadIcon, Loader } from '@components/ui';
import { OverlayPage } from '@components/partials';
import { ReportChartData } from '@hooks/reports/types';

export const SecurityReportLayout: React.FC = () => {
    
    const { reportId: id } = useParams<{ reportId: string }>();    
    const query = useReportPreviewQuery({
        reportId: id,
    });


    let chartData: ReportChartData | null = null;
    if (query.isFetched) {
        if (query.data?.jsonReportData) {
            chartData = JSON.parse(query.data?.jsonReportData);
        }
    }

    const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
    const downloadHasPermission = hasPermission(
        userRole,
        Permissions.ADMINS_SUPPORTUSER
    );

    const downloadFile = async () => {
        // download when report is done
    };

    const tabs: TabType[] = [
        {
            path: `/reports/security/${id}/preview/key-recommendations`,                       
            label: 'Key recommendations',
        },
        {
            path: `/reports/security/${id}/preview/secure-score`,            
            label: 'MS SecureScore',
        },
        {
            path: `/reports/security/${id}/preview/suspicious-activity`,
            label: 'Suspicious activity',
        },
        {
            path: `/reports/security/${id}/preview/login-security`,
            label: 'Login security',
        },
        {
            path: `/reports/security/${id}/preview/mailboxes`,
            label: 'Mailboxes',
        },
        {
            path: `/reports/security/${id}/preview/browser-os-versions`,
            label: 'Browser and OS versions',
        },
    ];

    return (
        <OverlayPage
            isFooterVisible={false}
            path="/reports"
            headerTitle={query.data?.name || ''}
            isLoading={query.isLoading}
        >
            {query.isLoading ? (
                <Loader />
            ) : (
                <>
                    {/* {downloadHasPermission &&
                        (
                            <button
                                className="inline-flex flex-shrink-0 items-center justify-center rounded-[24px] bg-interfaceColor-90 px-[16px] py-[10px] text-16 font-normal leading-6 tracking-[0.01em] text-white transition duration-300 ease-in-out hover:bg-interfaceColor-150 hover:text-interfaceColor-10 focus:shadow-focus disabled:bg-interfaceColor-20 disabled:text-interfaceColor-80"
                                onClick={() => downloadFile()}
                            >
                                <DownloadIcon
                                    classNames="mr-2"
                                    color="text-primaryBrandColor-150"
                                />
                                Download report
                            </button>
                        )} */}

                    <div className="mt-[5px]">
                        <Tab  tabs={tabs} className={'mb-[0px]'}  />
                    </div>
                </>
            )}
        </OverlayPage>
    );
};
