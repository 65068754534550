import {
    DEFAULT_PAGE_SIZE,
    OUTDATED_OS_ACCOUNTS_SORT_BY,
    OUTDATED_OS_ACCOUNTS_TABLE_COLUMNS,
    USERTYPE_FILTERS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { PeopleSortBy } from '@hooks/people/types';
import { useOutdatedOSAccountsPreviewQuery } from '@hooks/security';
import { usePagination } from '@hooks/utils/pagination';
import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { useLocation } from 'react-router-dom';
import { createCellValue } from './utils';
import { useIsMobile } from '@hooks/utils';
import { getGroupOrLocationFilterAsQueryParam } from '../utils';
import useInsightsStore from '@store/insightsStore';

const pageSize = DEFAULT_PAGE_SIZE;

const OutdatedOSAccountsPage: React.FC = () => {
    const days = new URLSearchParams(useLocation().search).get("days") || "30";
    const isMobile = useIsMobile();

    const [search, setSearch] = useState('');
    const { pageNumber, setPageNumber } = usePagination();

    const [queryParams, setQueryParams] = useQueryParams({
        search: withDefault(StringParam, ''),
        sortBy: withDefault(StringParam, 'logincount'),
        order: withDefault(StringParam, 'desc'),
        filter: withDefault(StringParam, ''),
    });
    const {
        groupIds, locationIds, filterData, transformedFilterData
      } = useInsightsStore();

    // table
    const query = useOutdatedOSAccountsPreviewQuery({
        pageSize,
        pageNumber,
        searchTerm: search,
        sortBy: queryParams.sortBy as PeopleSortBy,
        order: queryParams.order as OrderBy,
        days,
        locationList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, locationIds),
        groupList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, groupIds),
        OSType: queryParams.filter
            ? queryParams.filter
                .split(',')
                .filter((filter) => filterData?.operatingSystemNames.includes(filter))
            : [],
        accountType: queryParams.filter
            ? queryParams.filter
                .split(',')
                .filter((filter) => USERTYPE_FILTERS.includes(filter))
            : [],
    });

    const getTableData = useTableData(
        query,
        OUTDATED_OS_ACCOUNTS_TABLE_COLUMNS,
        createCellValue
    );

    const [shouldDownload, setShouldDownload] = useState(false);
    const { refetch: downloadCsv } = useDownloadCsv({
        shouldDownload,
        endpoint: `${API_ENDPOINTS.OUTDATED_OS_ACCOUNTS_EXPORT}`,
        params: {
            pageSize,
            pageNumber,
            searchTerm: search,
            sortBy: queryParams.sortBy as PeopleSortBy,
            order: queryParams.order as OrderBy,
            days,
            locationList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, locationIds),
            groupList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, groupIds),
            OSType: queryParams.filter
                ? queryParams.filter
                    .split(',')
                    .filter((filter) => filterData?.operatingSystemNames.includes(filter))
                : [],
            accountType: queryParams.filter
                ? queryParams.filter
                    .split(',')
                    .filter((filter) => USERTYPE_FILTERS.includes(filter))
                : [],
        },
        filename: `Clevr360_Outdated_OS_accounts_data_${formatDate(
            new Date().toString()
        )}.csv`,
    });

    const handleDownloadClick = () => {
        setShouldDownload(true);
        downloadCsv();
        setShouldDownload(false);
    };

    return (
        <div className="">
            <TableWrapper
                search={search}
                setSearch={setSearch}
                searchPlaceholder={'Search by account, location, group, operating system'}
                columns={OUTDATED_OS_ACCOUNTS_TABLE_COLUMNS}
                data={getTableData()}
                sortData={OUTDATED_OS_ACCOUNTS_SORT_BY}
                searchKey="search"
                query={query}
                filterData={transformedFilterData}
                refetchQuery={query.refetch}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                setPageNumber={setPageNumber}
                isCollapsable={true}
                hasFilterBanner={false}
                isLicensePage={true}
                sliceColumns={2}
                searchCountStatsLabel="accounts"
                isDownloadAvailable={true}
                filterHeaderText="Filter outdated OS data"
                handleDownload={handleDownloadClick}
                displayAsDropdownFilterItems={["Operating system"]}
                floatingFilterButtonLeft={!isMobile ? '190px' : ''}
                floatingFilterButton={true}
                floatingFilterButtonTop={'100px'}
                noResultTitle={search ? 'No results matching your search' 
                    : 'There are no outdated operating systems identified within your Microsoft 365 service.'}
                  noResultSubtitle={search ? 'Try a less specific search.' : ''}
          
            />
        </div>
    );
};

export default OutdatedOSAccountsPage;
