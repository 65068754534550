import { BasePreviewResponse, OrderBy } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';

export type UseReportsQueryOptions = {
  pageSize?: number;
  pageNumber?: number;
  searchTerm?: string;
  sortBy?: string;
  order?: OrderBy;
  groupTypeList?: string[];
  status?: string;
  renewalPeriod?: string;
  startDate?: string;
  endDate?: string;
};

export type ReportResponse = {
  totalItemCount: number;
  itemCount: number;
  totalPageCount: number;
  pageSize: number;
  currentPage: number;
  items: Reports[];
};

export type Reports = {
  id?: string;
  name: string;
  date: string;
  isRead: boolean;
  actions?: null;
  type: number;
};

export type ReportPreviewResponse = {
  id: string;
  isRead: boolean;
  jsonReportData: string;
  name: string;
  type: number;
  fromDate: string;
  toDate: string;
};

export type SecurityReportKeyRecommendation = {
  vendor: string;
  recommendationName: string;
  recommendationSeverity: string;
  recommendationArea: string;
  analystNote: string;
  recommendationSource: string;
  recommendationType: string;
  description: string;
  implementationProgress: string;
  affectedService: string;
  uKCyberEssentialsReference: string;
  relevantAccounts: string;
};

export type SecurityV2ReportSecureScoreResponse = {
  type: number;
  parameters: any;
};

export type SecurityV2ReportPreviewResponse<T> = BasePreviewResponse<T>;

export type ReportFiltersResponse = {
  groupType: GroupTypeReport[];
  status: StatusReport[];
};

export type GroupTypeReport = {
  groupType: string;
  value: number;
};

export type StatusReport = {
  status: string;
};

export type UseReportsPreviewQueryOptions = {
  reportId?: string;
};

export type ReportPdfUrlResponse = {
  pdfUrl: string;
};

export type ChartComponent = {
  type: number;
  keyParameters: KeyParameter[];
  dropDowns: ChartComponentDropdowns[];
};

type ChartComponentDropdowns = {
  dropDownItems: MenuItem[];
  preselectedValue: string;
};

export type KeyParameter = {
  key: string;
  parameters: {
    title: string;
    unassigned?: number;
    assigned?: number;
    inactive?: number;
    active?: number;
    overassigned?: number;
    percentage?: string | number;
    labels?: string[];
    data?: any;
    total?: number;
    isPercentIncreased?: boolean;
    lineColors?: string[] | null;
    legends?: string[] | null;
    percentText?: string | null;
  };
};

export type ReportChartData = {
  version?: string;
  fromDate?: string;
  toDate?: string;
  components: ChartComponent[];
};

export type ReportSelectedVendors = {
  [key: number]: string;
};


export const SecurityReportTabs: { [key: string]: string } = {
  "key-recommendations": "Key Recommendations",
  "secure-score": 'MS Secure Score',
  "mailboxes": 'Mailboxes',
  "suspicious-activity": 'SuspiciousActivity',
  "login-security": 'Login activity',
  "browser-os-versions": 'Outdated OS & Browsers',
}

export const securityReportSeverityOrder: Record<string, number> = {
  'High': 1,
  'Medium': 2,
  'Low': 3,
};
